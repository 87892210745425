import {Accordion, AccordionDetails, AccordionSummary, Box, Button, Divider, styled, TextField, Typography} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import moment from 'moment/moment';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {downloadProfileMedicalEventDocument} from '../../../../../api/services/filesService';
import {FileDownload, UploadFile} from '@mui/icons-material';
import React, {useState} from 'react';
import {useSelector} from 'react-redux';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import {useGetMedicalEventTypesQuery} from '../../../../../api/services/lookupsService';
import Uploader from '../../../../SchoolManagement/Setup/Uploader';
import {generateUUID} from '../../../../../utility/uuidUtil';
import {getRole} from '../../../../../utility/jwtUtil';
import {ROLES} from '../../../../../constants/roles';
import EditIcon from '@mui/icons-material/Edit';
import MedicalEventPopup from './MedicalEventPopup';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';

/* eslint-disable react/prop-types */

const MedicalEvents = ({
    readonly,
    medicalEvents,
    downloadFile,
    studentId,
    selectedFiles,
    isUploadSizeExceedsLimit,
    totalUploadFilesSize,
    handleSelectFiles,
    handleFileDeleteFromUploadContext,
    setIsUploadSizeExceedsLimit,
    updateMedicalEvents,
    setTotalUploadFilesSize,
    medicalInfo,
    refetchData,
    eventRows,
    handleSave,
    isEventsReadonly
}) => {
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const [openEventDialog, setOpenEventDialog] = useState(false);
    const [openAddEventDocumentsDialog, setOpenAddEventDocumentsDialog] = useState(false);
    const [selectedMedicalEvent, setSelectedMedicalEvent] = useState(undefined);

    const {data: medicalEventLookups = [],} = useGetMedicalEventTypesQuery();

    const handleDocumentsUploaded = async () => {
        const newFiles = selectedFiles.map(file => ({
            file,
            summary: '',
            note: '',
            attachmentName: file.name,
            isActive: true,
            uuid: generateUUID()
        }));

        setTotalUploadFilesSize(totalUploadFilesSize - selectedFiles.reduce((total, file) => {
            return total + file.size;
        }, 0) / (1024 * 1024))

        const updatedEvent = {
            ...selectedMedicalEvent,
            documents: selectedMedicalEvent.documents
                ? [...newFiles, ...selectedMedicalEvent.documents.map(e => ({...e}))]
                : [...newFiles]
        };

        updateMedicalEvents(updatedEvent);
        handleCloseDialog();
    };

    const MedicalRow = styled(Box)({
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    });

    const handleUpdateDocumentFields = async (event, document, newValue, property, deleteFlag) => {
        let eventToUpdate = medicalEvents.find(e => event.uuid ? event.uuid === e.uuid : e.id === event.id);

        if (deleteFlag) {
            const updatedDocuments = eventToUpdate.documents.filter(e =>
                !(document.fileId ? e.fileId === document.fileId : e.uuid === document.uuid)
            );

            updateMedicalEvents({
                ...eventToUpdate,
                documents: updatedDocuments
            });
            return;
        }

        let documentToUpdateIndex = eventToUpdate.documents.findIndex(e =>
            document.fileId ? e.fileId === document.fileId : e.uuid === document.uuid
        );

        let updatedDocument = {
            ...eventToUpdate.documents[documentToUpdateIndex],
            [property]: newValue
        };

        let updatedDocuments = [
            ...eventToUpdate.documents.slice(0, documentToUpdateIndex),
            updatedDocument,
            ...eventToUpdate.documents.slice(documentToUpdateIndex + 1)
        ];

        updateMedicalEvents({
            ...eventToUpdate,
            documents: updatedDocuments
        });
    };

    const handleCloseDialog = () => {
        setOpenEventDialog(false);
        setOpenAddEventDocumentsDialog(false)
        setSelectedMedicalEvent(undefined);
    }

    const isNothingChanged = () => {
        const isEventRowsChanged = eventRows?.filter(row => row?.isEdited || row?.isDeleted || row?.isNew).length === 0;

        return isEventRowsChanged;
    };

    // Check if has a summary if a document is added
    const hasMedicalEventDocumentSummary = () => {
        const hasMedicalEventDocument = eventRows?.some(row => row?.documents?.length > 0);
        const hasMedicalEventSummary = eventRows?.every(row =>
            !row?.documents ||
        row?.documents.every(doc => doc?.summary && doc?.summary.trim() !== '')
        );
        return !hasMedicalEventDocument || hasMedicalEventSummary;
    };

    const isEventsInitialised = eventRows?.length > 0;

    return (
        <>
            <ConfirmationWrapper
                readonly={readonly}
                confirmData={{
                    confirmMessage: `I confirm that the information in the medical profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                    confirmedAt: medicalInfo?.medicalEventsConfirmedAt,
                    confirmedBy: medicalInfo?.medicalEventsConfirmedBy,
                    enableSaveButton: !isNothingChanged() && hasMedicalEventDocumentSummary(),
                    enableConfirmButton: isNothingChanged() && isEventsInitialised,
                    studentId,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES?.MedicalEvents,
                    handleSave,
                    onConfirmSuccess: async () => await refetchData(),
                    confirmButtonText: 'Confirm Medical Events'
                }}
            >
                <Typography variant="h5" py={2}>Medical Events</Typography>
                <Typography>
                Please use this section to add any medical event that takes place
                while {selectedProfile.name} is on roll at {currentSchool.name} that is not related to a diagnosed
                medical condition. For example,
                injuries as a result of an accident. This section may also be updated by {currentSchool.name}, if a
                medical event or incident occurs during the school day and you will receive an email alert to
                inform you of any updates.
                </Typography>
                {
                    !readonly && <Button
                        variant="contained"
                        sx={{mb: 2, mt: 2, width: 150}}
                        startIcon={<AddCircleOutlineIcon/>}
                        onClick={() => setOpenEventDialog(true)}
                    >
                    Add
                    </Button>
                }
                {medicalEvents?.filter(e => !e.isDeleted).map(event => {
                    return (
                        <Box key={event?.uuid || event?.id} py={1}>
                            <Box display={'flex'} justifyContent={'space-between'}>
                                <MedicalRow>
                                    <Box>
                                        <Typography
                                            fontWeight="bold">
                                            {medicalEventLookups?.eventTypes?.find(e => e.id === event.eventTypeId)?.description} - {medicalEventLookups?.medicalEvents?.find(e => e.id === event.eventId)?.description}
                                        </Typography>
                                        <Typography>
                                            {event.eventDate && `Date: ${moment(event.eventDate).format('DD-MMM-YYYY')}`}
                                        </Typography>
                                        <Typography>
                                            {event.followUpDate && `Follow Up: ${moment(event.followUpDate).format('DD-MMM-YYYY')}`}
                                        </Typography>
                                    </Box>
                                </MedicalRow>
                                {
                                    !readonly && ((getRole() === ROLES.PARENT && event.uuid) || getRole() !== ROLES.PARENT) &&
                                    <Box display={'flex'} sx={{height: '40px'}}>
                                        <IconButton
                                            onClick={() => {
                                                setSelectedMedicalEvent(event);
                                                setOpenEventDialog(true)
                                            }}>
                                            <EditIcon/>
                                        </IconButton>
                                        <IconButton
                                            onClick={() => updateMedicalEvents(event, true)}>
                                            <DeleteIcon/>
                                        </IconButton>
                                    </Box>
                                }
                            </Box>
                            <>
                                {!readonly && ((getRole() === ROLES.PARENT && event.uuid) || getRole() !== ROLES.PARENT) &&
                                    <Button
                                        variant="outlined"
                                        sx={{my: 1}}
                                        startIcon={<UploadFile/>}
                                        onClick={() => {
                                            setSelectedMedicalEvent(event)
                                            setOpenAddEventDocumentsDialog(true);
                                        }}
                                    >
                                        Upload Documents
                                    </Button>
                                }
                            </>
                            {event.documents && <>
                                {
                                    event.documents
                                        .map(file =>
                                            <Box key={file?.fileId || file?.uuid} display={'flex'}
                                                alignItems={'center'} py={1}>
                                                <Accordion sx={{width: '100%'}}>
                                                    <AccordionSummary expandIcon={
                                                        <ArrowDropDownIcon/>}>
                                                        <Box display="flex"
                                                            justifyContent="space-between"
                                                            alignItems="center"
                                                            sx={{
                                                                maxWidth: {
                                                                    xs: '250px',
                                                                    sm: '395px',
                                                                    md: '695px',
                                                                    lg: '995px',
                                                                    xl: '1050px',
                                                                }
                                                            }}>
                                                            <Typography
                                                                sx={{
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    flexGrow: 1,
                                                                    marginRight: '8px',
                                                                }}
                                                            >
                                                                {file.attachmentName}
                                                            </Typography>
                                                            {file.fileId && <IconButton
                                                                sx={{
                                                                    flexShrink: 0,
                                                                }}
                                                                onClick={async (e) => {
                                                                    e.stopPropagation()
                                                                    await downloadFile(downloadProfileMedicalEventDocument, {
                                                                        documentId: file.fileId,
                                                                        studentId
                                                                    })
                                                                }}
                                                            >
                                                                <FileDownload/>
                                                            </IconButton>
                                                            }
                                                        </Box>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <TextField
                                                            fullWidth
                                                            label={'Summary'}
                                                            required
                                                            value={file.summary}
                                                            disabled={!(!readonly && ((getRole() === ROLES.PARENT && event.uuid) || getRole() !== ROLES.PARENT))}
                                                            onChange={(e) => handleUpdateDocumentFields(event, file, e.target.value, 'summary')}
                                                        />
                                                        <TextField
                                                            sx={{my: 2}}
                                                            multiline
                                                            fullWidth
                                                            label={'Note'}
                                                            disabled={!(!readonly && ((getRole() === ROLES.PARENT && event.uuid) || getRole() !== ROLES.PARENT))}
                                                            onChange={(e) => handleUpdateDocumentFields(event, file, e.target.value, 'note')}
                                                            value={file.note}
                                                        />
                                                    </AccordionDetails>
                                                </Accordion>
                                                {!readonly && ((getRole() === ROLES.PARENT && event.uuid) || getRole() !== ROLES.PARENT) &&
                                                    <IconButton
                                                        onClick={() => handleUpdateDocumentFields(event, file, null, null, true)}>
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                }
                                            </Box>
                                        )
                                }
                            </>
                            }
                        </Box>
                    )
                }
                )}

                <AdmicityDialog
                    handleClose={handleCloseDialog}
                    title={!selectedMedicalEvent ? 'New Medical Event' : 'Edit Medical Event'}
                    open={openEventDialog}
                    actions={[]}
                >
                    <MedicalEventPopup
                        selectedMedicalEvent={selectedMedicalEvent}
                        medicalEventLookups={medicalEventLookups}
                        onSubmit={(data) => {
                            updateMedicalEvents({
                                ...selectedMedicalEvent,
                                ...data
                            });
                            handleCloseDialog();
                        }}
                        onClose={handleCloseDialog}
                    />
                </AdmicityDialog>

                <AdmicityDialog
                    handleClose={() => {
                        handleCloseDialog();
                        setOpenAddEventDocumentsDialog(false)
                    }}
                    title={'Add Documents'}
                    open={openAddEventDocumentsDialog}
                    actions={[
                        {label: 'Cancel', onClick: handleCloseDialog},
                        {
                            label: 'Upload',
                            onClick: handleDocumentsUploaded,
                            disabled: selectedFiles?.length === 0 || isUploadSizeExceedsLimit,
                        }
                    ]}
                    maxWidth={'md'}
                >
                    <Uploader
                        totalUploadSizeInMb={totalUploadFilesSize}
                        onFilesSelect={handleSelectFiles}
                        onFileDelete={handleFileDeleteFromUploadContext}
                        onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                        value={selectedFiles}
                    />
                </AdmicityDialog>
            </ConfirmationWrapper>
            <Divider sx={{mt: 2, mb: 2}}/>
        </>
    );
};

export default MedicalEvents;