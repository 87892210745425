import {ROLES} from '../../constants/roles';
import {apiService} from '../apiService';

const getFormResponsesBase = {
    transformErrorResponse: (_) => ({message: 'Failed to retrieve form requests'}),
    transformResponse: (response) => ({
        ...response,
        items: response.items.map(({data, ...rest}) => ({
            data: JSON.parse(data),
            ...rest
        }))
    })
};

const getFormRequestBaseOptions = {
    transformResponse: (response) => {
        const {
            id,
            name,
            schema,
            data,
            status,
            schoolName,
            studentForename,
            hisHer,
            heShe,
            himHer,
            studentFullName,
            placeholders,
            parentTitle,
            studentId,
            isParent
        } = response;
        return {
            formId: id,
            formName: name,
            schema: schema ? JSON.parse(schema) : {},
            data: data ? JSON.parse(data) : {},
            isParent,
            placeholderValues: placeholders
                ? JSON.parse(placeholders)
                : {
                    schoolName,
                    studentForename,
                    studentFullName,
                    hisHer,
                    heShe,
                    himHer,
                    parentTitle
                },
            status,
            studentId
        };
    },
    transformErrorResponse: (error) => {
        const errorMessages = {
            500: 'Internal server error.',
            404: 'Could not find the requested form.'
        };

        return {
            statusText: error.status,
            message: errorMessages[error.status] ?? 'Something went wrong.'
        };
    }
};

export const formRequestsService = apiService.injectEndpoints({
    endpoints: (builder) =>
        ({
            getFormRequests: builder.query({
                query: (body) => ({
                    url: `/forms/${body.formId}/requests`,
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
            getFormRequestsByParent: builder.query({
                query: ({pageNumber, pageSize}) => ({
                    url: '/parent/forms/requests',
                    params: {pageNumber, pageSize},
                }),
                ...getFormResponsesBase
            }),
            getFormRequest: builder.query({
                query: ({requestId, role}) => ({
                    url: ({
                        [ROLES.PARENT]: (requestId) => `/parent/forms/requests/${requestId}`,
                        [ROLES.STUDENT]: (requestId) => `/student/requests/${requestId}`,
                    }[role] || ((requestId) => `/forms/requests/${requestId}`))(requestId)
                }),
                ...getFormRequestBaseOptions
            }),
            getGeneralFormRequest: builder.query({
                query: ({formId, studentId}) => ({
                    url: `/forms/general/${formId}/students/${studentId}`
                }),
                ...getFormRequestBaseOptions
            }),
            submitForm: builder.mutation({
                query: (body) => ({
                    url: '/forms/submit',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: body,
                }),
                transformErrorResponse: (error) =>
                    error.status === 409
                        ? 'Form already submitted. Please refresh the page to view the submitted information.'
                        : 'Failed to submit form.'
            }),
            submitPublicForm: builder.mutation({
                query: (body) => ({
                    url: '/forms/public/submit-form',
                    method: 'POST',
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                    data: body,
                }),
                transformErrorResponse: () => 'Failed to submit form.'
            }),
            getFormsByStudent: builder.query({
                query: (body) => ({
                    url: `/forms/requests/student/${body.studentId}`,
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
            getGeneralForms: builder.query({
                query: (body) => ({
                    url: '/forms/general',
                    method: 'POST',
                    data: body,
                }),
                transformResponse: (response) => ({
                    ...response,
                    items: response.items.map(({formName, ...rest}) => ({
                        name: formName,
                        ...rest
                    }))
                })
            }),
            getFormsByCurrentStudent: builder.query({
                query: (body) => ({
                    url: '/student/requests',
                    method: 'POST',
                    data: body,
                }),
                ...getFormResponsesBase
            }),
            getUsersSubmittedFormRequest: builder.query({
                query: (requestId) => ({
                    url: `/forms/requests/${requestId}/submitted-users`
                })
            }),
            resendFormRequest: builder.mutation({
                query: (body) => ({
                    url: '/forms/request/resend',
                    method: 'POST',
                    data: body,
                }),
                transformErrorResponse: () => 'Failed to resend the form.'
            }),
        }),
});

export const {
    useGetFormRequestsQuery,
    useGetFormRequestsByParentQuery,
    useGetFormRequestQuery,
    useGetGeneralFormRequestQuery,
    useSubmitFormMutation,
    useSubmitPublicFormMutation,
    useGetFormsByStudentQuery,
    useGetGeneralFormsQuery,
    useGetFormsByCurrentStudentQuery,
    useGetUsersSubmittedFormRequestQuery,
    useResendFormRequestMutation
} = formRequestsService;
