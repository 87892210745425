import React, {useEffect, useState} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {getRouteMatchPath} from '../../../../../utility/routeUtil';
import routes from '../../../../../routes/routes';
import {resetTableState} from '../../../../../store/tableSlice';

const StudentFormsTab = () => {
    const dispatch = useDispatch();
    const {pathname} = useLocation();
    const path = getRouteMatchPath(routes, pathname);
    const [formsState, setFormsState] = useState(null);

    useEffect(() => {
        return () => {
            dispatch(resetTableState(path));
            dispatch(resetTableState(`${path}/general`));
        };
    }, [dispatch]);

    return (
        <Outlet context={{key: path, formsState, setFormsState}}/>
    );
};

export default StudentFormsTab;