import React, {useEffect, useRef, useState} from 'react';
import {Box, Stack} from '@mui/material';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import RequiredCheckbox from './Shared/RequiredCheckbox';
import RichEditor from './Shared/RichEditor';
import RichEditorResultText from './Shared/RichEditorResultText';
import {stripHtmlTags} from './AdditionalNotes';
import {DateTimePicker} from '@mui/x-date-pickers';
import moment from 'moment/moment';

const DateTime = ({
                        index,
                        id,
                        value,
                        onChange,
                        description,
                        enableValidation,
                        isEditMode,
                        isFormArchived,
                        readonly,
                        placeholderValues,
                        accessLevel,
                        onUpdateDescription,
                        componentToEditIndex,
                        submitMode,
                    }) => {
    const [currentValue, setCurrentValue] = useState(description);
    const prevPropRef = useRef();

    useEffect(() => {
        setCurrentValue(description)
    }, [description]);

    useEffect(() => {
        if (prevPropRef.current === index && componentToEditIndex !== prevPropRef.current) {
            onUpdateDescription({index, description: stripHtmlTags(currentValue), formattedDescription: currentValue});
        }
        prevPropRef.current = componentToEditIndex;
    }, [componentToEditIndex]);

    const formatDateTime = (date) => {
        return moment(date).format('D MMM YYYY HH:mm');
    }

    return (
        <Box>
            {isEditMode
                ? <>
                    <RichEditor value={description} onChange={onChange} id={id} index={index} isEditMode={isEditMode}
                                isFormLive={isFormArchived} accessLevel={accessLevel} currentDescription={currentValue}
                                setCurrentDescription={setCurrentValue}/>

                    <Stack flexDirection="row"
                           justifyContent="space-between"
                           gap={3}>
                        <RequiredCheckbox enableValidation={enableValidation} index={index} onChange={onChange}
                                          isFormArchived={isFormArchived}/>
                    </Stack>
                </>
                : <>
                    <RichEditorResultText id={id} description={description} placeholderValues={placeholderValues}
                                          readonly={readonly} enableValidation={enableValidation}/>
                    {!readonly
                        ? <DateTimePicker
                            key={id}
                            slotProps={{ textField: { fullWidth: true }, field: { readOnly: true }}}
                            format="D MMM YYYY HH:mm"
                            ampm={false}
                            required={enableValidation}
                            onChange={newValue => onChange({id, value: newValue ? formatDateTime(newValue) : null})}
                            disabled={!submitMode}
                        />
                        : <Typography sx={{
                            width: {xs: '80vw', sm: '60vw', md: '45vw', lg: '45vw', xl: '45vw'},
                            wordWrap: 'break-word',
                            overflowWrap: 'break-word'
                        }}>
                            {value && formatDateTime(value)}
                        </Typography>
                    }
                </>
            }
        </Box>
    );
};

DateTime.propTypes = {
    id: PropTypes.string,
    value: PropTypes.string,
    index: PropTypes.number,
    validation: PropTypes.object,
    description: PropTypes.string,
    onChange: PropTypes.func,
    enableValidation: PropTypes.bool,
    isEditMode: PropTypes.bool,
    isFormArchived: PropTypes.bool,
    readonly: PropTypes.bool,
    placeholderValues: PropTypes.object,
    accessLevel: PropTypes.number,
    onUpdateDescription: PropTypes.func,
    componentToEditIndex: PropTypes.number,
    submitMode: PropTypes.bool,
};

export default DateTime;