const PERSON_STATUS = {
    APPLICANT: 'APPLICANT',
    STUDENT: 'STUDENT',
};

const REQUIRED_PERMISSIONS_BY_ROUTE = {
    basic: {
        [PERSON_STATUS.APPLICANT]: [
            'Permission.ApplicantProfile.Edit',
            'Permission.ApplicantProfile.View',
        ],
        [PERSON_STATUS.STUDENT]: [
            'Permission.StudentProfile.Edit',
            'Permission.StudentProfile.View',
        ],
    },
    contact: {
        [PERSON_STATUS.APPLICANT]: [
            'Permission.ApplicantContacts.Edit',
            'Permission.ApplicantContacts.View',
            'Permission.ApplicantEthnicity.Edit',
            'Permission.ApplicantEthnicity.View',
        ],
        [PERSON_STATUS.STUDENT]: [
            'Permission.StudentContacts.Edit',
            'Permission.StudentContacts.View',
            'Permission.StudentEthnicity.Edit',
            'Permission.StudentEthnicity.View',
        ]
    },
    sen: {
        [PERSON_STATUS.APPLICANT]: [
            'Permission.ApplicantSEN.Edit',
            'Permission.ApplicantSEN.View',
        ],
        [PERSON_STATUS.STUDENT]: [
            'Permission.StudentSEN.Edit',
            'Permission.StudentSEN.View',
        ]
    },
    ethnicity: {
        [PERSON_STATUS.APPLICANT]: [
            'Permission.ApplicantDietaryNeedsAndFoodAllergies.Edit',
            'Permission.ApplicantDietaryNeedsAndFoodAllergies.View',
            'Permission.ApplicantFreeSchoolMealsEligibility.Edit',
            'Permission.ApplicantFreeSchoolMealsEligibility.View',
            'Permission.ApplicantWelfare.Edit',
            'Permission.ApplicantWelfare.View',
        ],
        [PERSON_STATUS.STUDENT]: [
            'Permission.StudentDietaryNeedsAndFoodAllergies.Edit',
            'Permission.StudentDietaryNeedsAndFoodAllergies.View',
            'Permission.StudentFreeSchoolMealsEligibility.Edit',
            'Permission.StudentFreeSchoolMealsEligibility.View',
            'Permission.StudentWelfare.Edit',
            'Permission.StudentWelfare.View',
        ]
    },
    consents: {
        [PERSON_STATUS.APPLICANT]: [
            'Permission.ApplicantConsents.View',
            'Permission.ApplicantConsents.Edit',
            'Permission.ApplicantTravelMode.View',
            'Permission.ApplicantTravelMode.Edit'
        ],
        [PERSON_STATUS.STUDENT]: [
            'Permission.StudentConsents.View',
            'Permission.StudentConsents.Edit',
            'Permission.StudentTravelMode.View',
            'Permission.StudentTravelMode.Edit'
        ]
    },
    medical: {
        [PERSON_STATUS.APPLICANT]: [
            'Permission.ApplicantMedicalConditions.View',
            'Permission.ApplicantMedicalConditions.Edit',
            'Permission.ApplicantMedicalEvents.View',
            'Permission.ApplicantMedicalEvents.Edit',
            'Permission.ApplicantMedicalNotes.View',
            'Permission.ApplicantMedicalNotes.Edit',
            'Permission.ApplicantGpInfo.View',
            'Permission.ApplicantGpInfo.Edit',
            'Permission.ApplicantMedicalConsents.View',
            'Permission.ApplicantMedicalConsents.Edit'
        ],
        [PERSON_STATUS.STUDENT]: [
            'Permission.StudentMedicalConditions.View',
            'Permission.StudentMedicalConditions.Edit',
            'Permission.StudentMedicalEvents.View',
            'Permission.StudentMedicalEvents.Edit',
            'Permission.StudentMedicalNotes.View',
            'Permission.StudentMedicalNotes.Edit',
            'Permission.StudentGpInfo.View',
            'Permission.StudentGpInfo.Edit',
            'Permission.StudentMedicalConsents.View',
            'Permission.StudentMedicalConsents.Edit',
        ]
    },
    groups: {
        [PERSON_STATUS.APPLICANT]: [
            'Permission.ApplicantProfile.Edit',
            'Permission.ApplicantProfile.View',
        ],
        [PERSON_STATUS.STUDENT]: [
            'Permission.StudentProfile.Edit',
            'Permission.StudentProfile.View',
        ]
    },

};

const shouldRender = (userPermissions, requiredPermissions, isApplicant) =>
    requiredPermissions[isApplicant ? PERSON_STATUS.APPLICANT : PERSON_STATUS.STUDENT].some(x => userPermissions.includes(x));

export const tabs = [
    {
        label: 'Basic',
        route: 'basic',
        shouldRender: (permissions, isApplicant) => shouldRender(
            permissions,
            REQUIRED_PERMISSIONS_BY_ROUTE.basic,
            isApplicant
        ),
        showLoadingSpinner: true
    },
    {
        label: 'Contacts and Cultural Information',
        route: 'contact',
        shouldRender: (permissions, isApplicant) => shouldRender(
            permissions,
            REQUIRED_PERMISSIONS_BY_ROUTE.contact,
            isApplicant
        ),
        showLoadingSpinner: true
    },
    {
        label: 'Medical',
        route: 'medical',
        shouldRender: (permissions, isApplicant) => shouldRender(
            permissions,
            REQUIRED_PERMISSIONS_BY_ROUTE.medical,
            isApplicant
        ),
        showLoadingSpinner: true
    },
    {
        label: 'SEN and Disabilities',
        route: 'sen',
        shouldRender: (permissions, isApplicant) => shouldRender(
            permissions,
            REQUIRED_PERMISSIONS_BY_ROUTE.sen,
            isApplicant
        ),
        showLoadingSpinner: true
    },
    {
        label: 'Dietary and Welfare',
        route: 'ethnicity',
        shouldRender: (permissions, isApplicant) => shouldRender(
            permissions,
            REQUIRED_PERMISSIONS_BY_ROUTE.ethnicity,
            isApplicant
        ),
        showLoadingSpinner: true
    },
    {
        label: 'Consents and Transport',
        route: 'consents',
        shouldRender: (permissions, isApplicant) => shouldRender(
            permissions,
            REQUIRED_PERMISSIONS_BY_ROUTE.consents,
            isApplicant
        ),
        showLoadingSpinner: true
    },
    {
        label: 'Documentation',
        route: 'documentation',
        shouldRender: (_) => true,
        showLoadingSpinner: true
    },
    {
        label: 'Forms',
        route: 'forms',
        shouldRender: (_) => true,
        showLoadingSpinner: false
    },
    {
        label: 'Groups',
        route: 'groups',
        shouldRender: (permissions, isApplicant) => shouldRender(
            permissions,
            REQUIRED_PERMISSIONS_BY_ROUTE.groups,
            isApplicant
        ),
        showLoadingSpinner: false
    }
];