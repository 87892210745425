import {useNavigate, useOutletContext, useParams} from 'react-router-dom';
import {useGetFormQuery} from '../../../../api/services/formsService';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {FormBuilderComponentTypes} from '../FormBuilder/ComponentList';
import {Box, Button, Link, Stack, styled, Typography} from '@mui/material';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import AdmicityStatus from '../../../../shared-components/AdmicityStatus';
import {getFormRequestStatusInfo} from '../../RequestAndResponses/common/common';
import {
    downloadFormDocument,
    downloadFormResponsesCsvReport,
    downloadFormResponsesPdfReport,
    downloadFormResponsesXlsxReport
} from '../../../../api/services/filesService';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AdmicityTableV2 from '../../../../shared-components/Table/V2/AdmicityTableV2';
import {
    useGetFormRequestsQuery,
    useGetUsersSubmittedFormRequestQuery, useResendFormRequestMutation
} from '../../../../api/services/formRequestsService';
import {FORM_REQUEST_STATUSES, FORM_REQUEST_STATUSES_NAMES} from '../../../../constants/formRequestStatuses';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import {formatDate, formatDateTime, isDateExpired} from '../../../../utility/dateUtil';
import {FORM_ACCESS_LEVELS} from '../../../../constants/formAccessLevels';
import DateRangePicker from '../../../../shared-components/Table/V2/components/columnFilters/DateRangePicker';
import FILTER_OPERATIONS from '../../../../constants/filterOperations';
import useTableState from '../../../../utility/hooks/useTableState';
import useFileDownloader from '../../../../utility/hooks/useFileDownloader';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import GroupAddRoundedIcon from '@mui/icons-material/GroupAddRounded';
import {useCreateGroupFromFormResponsesMutation} from '../../../../api/services/groupService';
import useNotification from '../../../../utility/hooks/useNotification';
import CreateGroupFromResponsesDialog from './CreateGroupFromResponsesDialog';
import Tooltip from '@mui/material/Tooltip';
import CustomAutocomplete from '../../../../shared-components/Table/V2/components/columnFilters/CustomAutocomplete';
import FORM_AUDIENCE_TYPE from '../../../../constants/FormAudienceType';
import { MailOutline } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import {useConfirm} from 'material-ui-confirm';
import {useDispatch} from 'react-redux';
import {FORM_STATUSES} from '../../../../constants/formStatuses';
/* eslint-disable react/prop-types */

const StyledCell = styled('div')({
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxHeight: '24px',
});

const CustomCell = ({cell}) => {
    const value = cell.getValue();
    return (
        <Tooltip title={value} arrow>
            <StyledCell>
                {value}
            </StyledCell>
        </Tooltip>
    );
};

const CustomHeader = ({label}) => {
    return (
        <Tooltip title={label} arrow>
            <StyledCell>
                {label}
            </StyledCell>
        </Tooltip>
    );
};

const extractDataFromComponentByType = (componentDefinition) =>
    ({
        [FormBuilderComponentTypes.SingleChoice]: (id) => [{
            [componentDefinition.id]: componentDefinition.options.find(x => x.id === id)?.description
        }],
        [FormBuilderComponentTypes.OfferStatusChoice]: (id) => [{
            [componentDefinition.id]: componentDefinition.options.find(x => x.id === id)?.description
        }],
        [FormBuilderComponentTypes.Dropdown]: (id) => [{
            [componentDefinition.id]: componentDefinition.options.find(x => x.id === id)?.description
        }],
        [FormBuilderComponentTypes.MultipleChoice]: (ids) =>
            ids
                ? componentDefinition.options
                    .filter(x => ids.includes(x.id))
                    .map(x => ({[x.id]: true}))
                : [],
        [FormBuilderComponentTypes.TickBoxGrid]: (values) =>
            values
                ? Object.entries(values).map(([rowId, selectedColumnIds]) => (
                    {
                        [rowId]: componentDefinition.columns
                            .filter(x => selectedColumnIds.includes(x.id))
                            .map(x => x.title)
                    }
                ))
                : [],
        [FormBuilderComponentTypes.MultipleChoiceGrid]: (values) =>
            values
                ? Object.entries(values).map(([rowId, selectedColumnId]) => ({
                    [rowId]: componentDefinition.columns
                        .find(x => x.id === selectedColumnId)
                        ?.title
                }))
                : [],
        [FormBuilderComponentTypes.Signature]: (value) => [{
            [componentDefinition.id]: value ? 'Signed' : ''
        }],
        [FormBuilderComponentTypes.Attachment]: () => [{
            [componentDefinition.id]: componentDefinition.attachments
        }],
    })[componentDefinition.type] ??
    function (value) {
        return [{[componentDefinition.id]: value}];
    };

const FormResponses = () => {
    const {key} = useOutletContext();
    const {
        pagination,
        filters,
        columnVisibility,
        onFiltersChange,
        onPaginationChange,
        onColumnVisibilityChange
    } = useTableState(key);
    const {id} = useParams();
    const {
        data: form = {},
        isLoading: isLoadingFormData
    } = useGetFormQuery(id);
    const {
        data: responses = {},
        isLoading: isLoadingFormResponses,
        isFetching: isFetchingFormResponses
    } = useGetFormRequestsQuery({
        formId: id,
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageIndex,
        filters: filters.filters,
        responseFilters: filters.responseFilters,
    }, {skip: form.schema === undefined});
    const [createGroup, {
        isSuccess: isCreateGroupSuccess,
        isError: isCreateGroupError,
        error: createGroupError
    }] = useCreateGroupFromFormResponsesMutation();
    const [resendFormRequest, {
        isSuccess: isResendFormRequestSuccess,
        isError: isResendFormRequestError
    }] = useResendFormRequestMutation();
    const usersSubmittedFormResult = useGetUsersSubmittedFormRequestQuery(id);
    const navigate = useNavigate();
    const downloadFile = useFileDownloader();
    const [openCreateGroupDialog, setOpenCreateGroupDialog] = useState(false);
    const {showDetailedErrorNotification, showSuccessNotification} = useNotification();
    const confirm = useConfirm();
    const dispatch = useDispatch();

    useEffect(() => {
        if (isCreateGroupSuccess) {
            showSuccessNotification('Group created');
            handleCloseCreateGroupDialog();
        } else if (isCreateGroupError) {
            showDetailedErrorNotification('Failed to create group', createGroupError.data.validationErrors);
        }
    }, [isCreateGroupSuccess, isCreateGroupError]);

    useEffect(() => {
        if (isResendFormRequestSuccess) {
            showSuccessNotification('Email sent');
        } else if (isResendFormRequestError) {
            showDetailedErrorNotification('Failed to resend form request');
        }
    }, [isResendFormRequestSuccess, isResendFormRequestError]);

    const handleResend = (formId, formRequestId, formRequestAssignmentId) => {
        confirm({
            title: 'Confirmation',
            description: 'Are you sure that you want to resend form request email?',
            confirmationText: 'Confirm'
        })
            .then(() => {
                dispatch(resendFormRequest({
                    formId,
                    formRequestId,
                    formRequestAssignmentId
                }));
            })
            .catch(() => {
            });
    };

    const baseColumns = useMemo(() => (
        {
            privateFormColumns: [
                {
                    accessorKey: 'formName',
                    header: 'Form name',
                    size: 180,
                    enableColumnFilter: false,
                    Cell: CustomCell
                },
                ...(form.audience === FORM_AUDIENCE_TYPE.parentsForChildCompletion || form.audience === FORM_AUDIENCE_TYPE.parentsForChildCompletion || form.audience === FORM_AUDIENCE_TYPE.previousSchool
                    ? [
                        {
                            accessorKey: 'student.forename',
                            header: 'First name',
                            size: 180
                        },
                        {
                            accessorKey: 'student.legalSurname',
                            header: 'Last name',
                            size: 180
                        }
                    ]
                    : []),
                {
                    accessorKey: 'contacts',
                    header: 'Assignee',
                    minSize: 250,
                    maxSize: 500,
                    enableColumnFilter: false,
                    Cell: ({cell, row}) =>
                        <Stack direction="column" gap={1}>
                            {
                                ((cell.getValue() ?? []).map((contact, index) => (
                                        <Stack
                                            key={`assignee_${index}`}
                                            flexDirection="row"
                                            alignItems="center"
                                            onClick={e => e.stopPropagation()}
                                            sx={{
                                                width: '100%'
                                            }}
                                        >
                                            <AccountCircleRoundedIcon
                                                color="text.secondary"
                                                fontSize="medium"
                                                sx={{
                                                    color: 'text.disabled',
                                                    marginRight: 1
                                                }}/>
                                            <Stack direction="column">
                                                <Typography
                                                    variant="body1">{`${contact.forename} ${contact.surname}`}</Typography>
                                                <Typography variant="caption">{contact.email}</Typography>
                                            </Stack>
                                            {(row.original.status === FORM_REQUEST_STATUSES.sent ||
                                                    row.original.status === FORM_REQUEST_STATUSES.failedToSend ||
                                                    row.original.status === FORM_REQUEST_STATUSES.created) &&
                                                form.status === FORM_STATUSES.active &&
                                                (!form.expirationDate || !isDateExpired(form.expirationDate)) &&
                                                <IconButton onClick={() => handleResend(
                                                    form.id,
                                                    row.original.id,
                                                    contact.formRequestAssignmentId
                                                )} title="Resend Form Request">
                                                    <MailOutline
                                                        sx={{color: 'primary.main'}}/>
                                                </IconButton>
                                            }
                                        </Stack>
                                    ))
                                )
                            }
                        </Stack>,
                },
                {
                    id: 'editedBy',
                    accessorKey: 'editedBy.fullname',
                    header: 'Submitted By',
                    Filter: CustomAutocomplete,
                    filterSelectOptions: Object.values(usersSubmittedFormResult.data
                        ?.reduce((acc, x) => {
                            if (x.firstName.toLowerCase() === 'school') {
                                if (!acc.school) {
                                    acc.school = {
                                        value: x.id,
                                        label: `${x.firstName} ${x.lastName}`
                                    };
                                } else {
                                    acc.school.value = `${acc.school.value},${x.id}`;
                                }
                            } else {
                                acc[x.id] = {
                                    value: x.id,
                                    label: `${x.firstName} ${x.lastName}`
                                };
                            }

                            return acc;
                        }, {}) || {}),
                    filterFn: FILTER_OPERATIONS.MULTISELECT,
                    size: 180,
                },
                {
                    accessorKey: 'editedAt',
                    header: 'Submitted At',
                    Filter: DateRangePicker,
                    filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
                    Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
                },
                {
                    accessorKey: 'status',
                    header: 'Status',
                    size: 180,
                    filterFn: FILTER_OPERATIONS.EQUALS,
                    filterVariant: 'select',
                    filterSelectOptions: Object.values(FORM_REQUEST_STATUSES).map(statusId => ({
                        value: statusId,
                        label: FORM_REQUEST_STATUSES_NAMES[statusId]
                    })),
                    Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())}/>
                }
            ],
            publicFormColumns: [
                {
                    accessorKey: 'formName',
                    header: 'Form name',
                    size: 180,
                    enableColumnFilter: false
                },
                {
                    accessorKey: 'editedAt',
                    header: 'Submitted At',
                    Filter: DateRangePicker,
                    filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
                    Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
                },
                {
                    accessorKey: 'status',
                    header: 'Status',
                    size: 180,
                    filterFn: FILTER_OPERATIONS.EQUALS,
                    filterVariant: 'select',
                    filterSelectOptions: Object.values(FORM_REQUEST_STATUSES).map(statusId => ({
                        value: statusId,
                        label: FORM_REQUEST_STATUSES_NAMES[statusId]
                    })),
                    Cell: ({cell}) => <AdmicityStatus {...getFormRequestStatusInfo(cell.getValue())}/>
                }
            ]
        }
    ), [usersSubmittedFormResult, form]);

    const generateColumnPropsByComponentDefinition = useCallback((componentDefinition) =>
        ({
            [FormBuilderComponentTypes.CheckboxQuestion]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    Header: (<CustomHeader label={componentDefinition.description}/>),
                    Cell: ({cell}) => cell.getValue() ? <CheckBoxRoundedIcon color="primary"/> : '',
                    filterVariant: 'select',
                    filterSelectOptions: [{value: true, label: 'Selected'}]
                }
            ],
            [FormBuilderComponentTypes.Dropdown]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    Header: (<CustomHeader label={componentDefinition.description}/>),
                    Cell: CustomCell,
                    filterVariant: 'select',
                    filterSelectOptions: componentDefinition.options.map(x => ({value: x.id, label: x.description}))
                }
            ],
            [FormBuilderComponentTypes.SingleChoice]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    Header: (<CustomHeader label={componentDefinition.description}/>),
                    Cell: CustomCell,
                    filterVariant: 'select',
                    filterSelectOptions: componentDefinition.options.map(x => ({value: x.id, label: x.description}))
                }
            ],
            [FormBuilderComponentTypes.OfferStatusChoice]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    Header: (<CustomHeader label={componentDefinition.description}/>),
                    Cell: CustomCell,
                    filterVariant: 'select',
                    filterSelectOptions: componentDefinition.options.map(x => ({value: x.id, label: x.description}))
                }
            ],
            [FormBuilderComponentTypes.MultipleChoice]: () => componentDefinition.options
                .map(x => ({
                    accessorKey: x.id,
                    header: `${componentDefinition.description} (${x.description})`,
                    Header: (<CustomHeader label={`${componentDefinition.description} (${x.description})`}/>),
                    Cell: ({cell}) => cell.getValue() ? <CheckBoxRoundedIcon color="primary"/> : '',
                    filterVariant: 'select',
                    filterSelectOptions: [{value: x.id, label: 'Selected'}]
                })),
            [FormBuilderComponentTypes.TickBoxGrid]: () => componentDefinition.rows
                .map(x => ({
                    accessorKey: x.id,
                    header: `${componentDefinition.description} (${x.title})`,
                    Header: (<CustomHeader label={`${componentDefinition.description} (${x.title})`}/>),
                    Cell: ({cell}) => {
                        const value = cell.getValue();
                        const displayValue = value ? value.join('; ') : '';
                        return (
                            <Tooltip title={displayValue} arrow>
                                <StyledCell>
                                    {displayValue}
                                </StyledCell>
                            </Tooltip>
                        );
                    },
                    filterVariant: 'multi-select',
                    filterSelectOptions: componentDefinition.columns.map(c => ({value: c.id, label: c.title})),
                    filterFn: FILTER_OPERATIONS.MULTISELECT
                })),
            [FormBuilderComponentTypes.MultipleChoiceGrid]: () => componentDefinition.rows
                .map(x => ({
                    accessorKey: x.id,
                    header: `${componentDefinition.description} (${x.title})`,
                    Header: (<CustomHeader label={`${componentDefinition.description} (${x.title})`}/>),
                    Cell: CustomCell,
                    filterVariant: 'select',
                    filterSelectOptions: componentDefinition.columns.map(c => ({value: c.id, label: c.title}))
                })),
            [FormBuilderComponentTypes.LinearScale]: () => [{
                accessorKey: componentDefinition.id,
                header: componentDefinition.description,
                Header: (<CustomHeader label={componentDefinition.description}/>),
                filterVariant: 'range-slider',
                filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE,
                muiFilterSliderProps: {
                    marks: true,
                    min: componentDefinition.startFrom,
                    max: componentDefinition.endAt,
                    step: 1
                }
            }],
            [FormBuilderComponentTypes.Attachment]: () => [{
                accessorKey: componentDefinition.id,
                header: componentDefinition.label,
                Header: (<CustomHeader label={componentDefinition.label}/>),
                enableColumnFilter: false
            }],
            [FormBuilderComponentTypes.Signature]: () => [{
                accessorKey: componentDefinition.id,
                header: componentDefinition.label,
                Header: (<CustomHeader label={componentDefinition.label}/>),
                enableColumnFilter: false
            }],
            [FormBuilderComponentTypes.Attachment]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.label,
                    Header: (<CustomHeader label={componentDefinition.label}/>),
                    enableColumnFilter: false,
                    Cell: ({cell}) => <Stack direction="column">
                        {
                            cell.getValue()?.map(x => (
                                <Link
                                    key={x.name}
                                    component="button"
                                    variant="body2"
                                    onClick={async () => await downloadFile(downloadFormDocument, {documentId: x.id})}
                                >
                                    {x.name}
                                </Link>
                            ))
                        }
                    </Stack>
                }
            ],
            [FormBuilderComponentTypes.Date]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    Header: (<CustomHeader label={componentDefinition.description}/>),
                    Filter: DateRangePicker,
                    filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE_DATE,
                    Cell: ({cell}) => cell.getValue() ? formatDate(cell.getValue()) : '',
                }
            ],
            [FormBuilderComponentTypes.DateTime]: () => [
                {
                    accessorKey: componentDefinition.id,
                    header: componentDefinition.description,
                    Header: (<CustomHeader label={componentDefinition.description}/>),
                    Filter: DateRangePicker,
                    filterFn: FILTER_OPERATIONS.BETWEEN_INCLUSIVE_DATE,
                    Cell: ({cell}) => cell.getValue() ? formatDateTime(cell.getValue()) : '',
                }
            ],
        })[componentDefinition.type] ??
        function () {
            return [];
        }, []);

    const formSchemaComponentTypes = useMemo(() =>
        (form.schema?.reduce((components, x) => {
            if (x.type !== FormBuilderComponentTypes.AdditionalNotes && x.type !== FormBuilderComponentTypes.Image) {
                components[x.id] = x;
            }
            return components;
        }, {})) ?? {}, [form.schema]);

    const handleColumnFiltersApply = (filters, quickFilters, filtersFns) => {
        const columnFilters = filters.reduce((acc, x) => {
                const baseColumn = baseColumns.publicFormColumns.find(c => c.accessorKey === x.id) ||
                    baseColumns.privateFormColumns.find(c => c.accessorKey === x.id || c.id === x.id);

                if (baseColumn) {
                    acc.filters.push({
                        propertyName: x.id,
                        value: x.id === 'editedBy' ? x.value.split(',') : x.value,
                        operation: filtersFns[baseColumn.accessorKey] || filtersFns[baseColumn.id]
                    });
                } else {
                    acc.responseFilters.push({
                        propertyName: x.id,
                        value: x.value,
                        jsonParentPaths: dynamicColumns.paths[x.id] ?? [],
                        operation: filtersFns[x.id],
                    });
                }

                return acc;
            }, {filters: [], responseFilters: []}
        );

        onFiltersChange({filters: columnFilters});
    };

    const handleColumnVisibilityChange = value => {
        const result = Object.entries(value).reduce((acc, [accessorKey, hide]) => {
                if (baseColumns.publicFormColumns.some(c => c.accessorKey === accessorKey) ||
                    baseColumns.privateFormColumns.some(c => c.accessorKey === accessorKey)) {
                    acc.default[accessorKey] = hide;
                } else {
                    acc.dynamic[id][accessorKey] = hide;
                }

                return acc;
            },
            {
                default: {},
                dynamic: {
                    [id]: {}
                }
            }
        );

        onColumnVisibilityChange({...columnVisibility, ...result});
    };

    const dynamicColumns = useMemo(() => {
        const dynamicColumns = Object.values(formSchemaComponentTypes).reduce((acc, x) => {
            const columns = generateColumnPropsByComponentDefinition(x)();

            acc.columns.push(...columns);
            columns.forEach(c => {
                if (c.accessorKey !== x.id) {
                    acc.paths[c.accessorKey] = [x.id];
                }
            });

            return acc;
        }, {columns: [], paths: {}});

        return {
            columns: dynamicColumns.columns.length
                ? [
                    ...(
                        {
                            [FORM_ACCESS_LEVELS.private]: baseColumns.privateFormColumns,
                            [FORM_ACCESS_LEVELS.public]: baseColumns.publicFormColumns,
                            [FORM_ACCESS_LEVELS.publicWithStudentLink]: baseColumns.privateFormColumns,
                        }[form.accessLevel] ?? []
                    ),
                    ...dynamicColumns.columns
                ]
                : [
                    ...(
                        {
                            [FORM_ACCESS_LEVELS.private]: baseColumns.privateFormColumns,
                            [FORM_ACCESS_LEVELS.public]: baseColumns.publicFormColumns,
                            [FORM_ACCESS_LEVELS.publicWithStudentLink]: baseColumns.privateFormColumns,
                        }[form.accessLevel] ?? []
                    )
                ],
            paths: dynamicColumns.paths
        };
    }, [form.accessLevel, formSchemaComponentTypes, usersSubmittedFormResult]);

    const data = useMemo(() => responses?.items
        ? responses.items.map(({data, ...rest}) => ({
                ...rest,
                ...Object.assign({},
                    ...Object.entries(formSchemaComponentTypes)
                        .flatMap(([componentId, componentDefinition]) =>
                            data
                                ? extractDataFromComponentByType(componentDefinition)(data[componentId]).filter(x => x)
                                : [])
                )
            })
        )
        : [], [responses.items]);

    const downloadCsvReport = async () =>
        await downloadFile(
            downloadFormResponsesCsvReport,
            {
                formId: id,
                filters: filters.filters,
                responseFilters: filters.responseFilters,
            });

    const downloadPdfReport = async (formRequestId, isBulk = true) => {
        if (!isBulk && formRequestId) {
            await downloadFile(
                downloadFormResponsesPdfReport,
                {
                    formId: id,
                    filters: filters.filters,
                    responseFilters: filters.responseFilters,
                    formRequestId
                });
        } else {
            await downloadFormResponsesPdfReport({
                formId: id,
                filters: filters.filters,
                responseFilters: filters.responseFilters,
                formRequestId: null
            });
            showSuccessNotification('The PDFs is being created. It will be sent to your email address when the process is complete.');
        }
    };

    const downloadXlsxReport = async () =>
        await downloadFile(
            downloadFormResponsesXlsxReport,
            {
                formId: id,
                filters: filters.filters,
                responseFilters: filters.responseFilters,
            });

    const handleCreateGroup = async data => {
        await createGroup({
            formId: id,
            ...data,
            responseFilters: [
                {
                    propertyName: data.relatedComponentId,
                    value: data.targetStateId,
                    jsonParentPaths: dynamicColumns.paths[data.relatedComponent] ?? [],
                    operation: FILTER_OPERATIONS.EQUALS,
                }
            ],
            parentGroupFilterId: data.parentGroupFilterId && data.parentGroupFilterId !== ''
                ? data.parentGroupFilterId
                : null,
        });
    };

    const getRowActions = (row) => {
        const actions = [
            {
                label: 'View form request',
                action: params => navigate(`${params.id}`)
            }
        ];
        if (row.status === FORM_REQUEST_STATUSES.submitted) {
            actions.push({
                label: 'Download PDF',
                action: params => downloadPdfReport(params.id, false)
            });
        }

        return actions;
    };

    const handleCloseCreateGroupDialog = () => setOpenCreateGroupDialog(false);

    return (
        <>
            <Box sx={{marginBottom: 2}}>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={() => navigate('/forms')}
                >
                    Back to forms
                </Button>
            </Box>
            <AdmicityTableV2
                columns={dynamicColumns.columns}
                data={data}
                initialState={{
                    columnFilters: Object.values(filters)
                        .filter(x => x)
                        .flatMap(x => x.map(f => ({id: f.propertyName, value: f.value})))
                }}
                isLoading={isLoadingFormResponses || isLoadingFormData}
                isRefetching={isFetchingFormResponses}
                totalItemCount={responses.totalCount}
                pagination={pagination}
                columnVisibility={{
                    ...columnVisibility.default,
                    ...(columnVisibility.dynamic?.[id]?.values ?? {})
                }}
                onPaginationModelChange={onPaginationChange}
                onColumnVisibilityChange={handleColumnVisibilityChange}
                onColumnFiltersApply={handleColumnFiltersApply}
                rowActions={row => getRowActions(row)}
                toolbarActions={
                    !form.allowMultipleSubmissions &&
                    (form.accessLevel === FORM_ACCESS_LEVELS.private ||
                    form.accessLevel === FORM_ACCESS_LEVELS.publicWithStudentLink)
                        ? [
                            {
                                type: 'button',
                                label: 'Create new group',
                                startIcon: <GroupAddRoundedIcon/>,
                                onClick: () => setOpenCreateGroupDialog(true)
                            }
                        ]
                        : []
                }
                toolbarIconActions={
                    [{
                        icon: <FileDownloadRoundedIcon/>,
                        title: 'Download report',
                        type: 'select',
                        hideIfEmpty: true,
                        menuItems: [
                            {
                                label: 'Download CSV',
                                onClick: downloadCsvReport
                            },
                            {
                                label: 'Download XLSX',
                                onClick: downloadXlsxReport
                            },
                            {
                                label: 'Download PDF',
                                onClick: downloadPdfReport
                            },
                        ]
                    }]
                }
                tableProps={{
                    enableSorting: false,
                    defaultColumn: {
                        size: 300,
                        maxSize: 1500,
                        minSize: 180,
                    }
                }}
            />
            <CreateGroupFromResponsesDialog
                onClose={handleCloseCreateGroupDialog}
                onSave={handleCreateGroup}
                title="Create new group"
                open={openCreateGroupDialog}
                schema={form.schema}
            />
        </>
    );
};

export default FormResponses;