import {useSelector} from 'react-redux';
import PermissionCheck from '../../Components/PermissionCheck';
import React from 'react';
import SenAndDisabilities from './SenAndDisabilities';

const SenAndDisabilitiesTab = () => {
    const isPersonApplicant = useSelector(state => state.profilesInfo.selectedProfile.isApplicant);

    return (
        <PermissionCheck
            permissionModule={isPersonApplicant ? 'ApplicantSEN' : 'StudentSEN'}
            render={(readonly) => <SenAndDisabilities readonly={readonly}/>}
        />
    );
};

export default SenAndDisabilitiesTab;