import {useSelector} from 'react-redux';
import PermissionCheck from '../../Components/PermissionCheck';
import React from 'react';
import BasicInfo from './BasicInfo';

const BasicInfoTab = () => {
    const isPersonApplicant = useSelector(state => state.profilesInfo.selectedProfile.isApplicant);

    return (
        <PermissionCheck
            permissionModule={isPersonApplicant ? 'ApplicantProfile' : 'StudentProfile'}
            render={(readonly) => <BasicInfo readonly={readonly}/>}
        />
    );
};

export default BasicInfoTab;