/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import {
    getAllLeafColumnDefs,
    MaterialReactTable,
    MRT_ToggleFiltersButton,
    MRT_ToggleFullScreenButton,
    MRT_ToggleRowActionMenuButton,
    useMaterialReactTable
} from 'material-react-table';
import React, {useEffect, useState} from 'react';
import {Badge, Box, Button, MenuItem, Stack, TextField, Typography, useMediaQuery, useTheme} from '@mui/material';
import {CustomActionsColumn} from './components/actions/CustomActionsColumn';
import FILTER_OPERATIONS from '../../../constants/filterOperations';
import FilterListRoundedIcon from '@mui/icons-material/FilterListRounded';
import IconButton from '@mui/material/IconButton';
import AdmicityIconMenu from '../../AdmicityIconMenu';
import CustomShowHideMenuItems from './components/actions/CustomShowHideMenuItems';

const baseTableProps = {
    muiTableHeadCellProps: {
        sx: {
            justifyContent: 'space-between',
            '& .Mui-TableHeadCell-Content-Wrapper': {
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                WebkitLineClamp: '5',
                whiteSpace: 'pre-wrap'
            }
        }
    },
    muiTableFooterProps: {
        sx: {
            outline: 'none'
        }
    },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    sortDescFirst: true,
    enableSortingRemoval: true,
    enableFilterMatchHighlighting: false,
    enableColumnResizing: true,
    enablePagination: true,
    enableDensityToggle: false,
    enableGlobalFilter: false,
    enableColumnActions: false
};

const getFilterFns = (columns, table) => Object.assign(
    {},
    ...getAllLeafColumnDefs(columns).map((col) =>
        ({
            [col.accessorKey]:
                typeof col.filterFn === 'function'
                    ? col.filterFn.name || 'custom'
                    : col.filterFn ||
                    (table.initialState?.columnFilterFns?.[col.accessorKey]) || FILTER_OPERATIONS.CONTAINS,
        }))
);

const countFiltersWithoutDefaults = (filters) =>
    filters.reduce((count, filter) => {
        if (!filter.value) {
            return count;
        }

        if (Array.isArray(filter.value)) {
            return filter.value.length !== 0 ? count + 1 : count;
        } else if (typeof filter.value === 'object' && filter.value != null) {
            return Object.keys(filter.value).length !== 0 ? count + 1 : count;
        } else if (typeof filter.value === 'string') {
            return filter.value.trim() !== '' ? count + 1 : count;
        } else {
            return count + 1;
        }
    }, 0);

export const AdmicityTableV2 = (
    {
        initialState = {
            columnFilters: []
        },
        data,
        columns,
        isLoading,
        isRefetching,
        totalItemCount,
        pagination,
        columnVisibility,
        tableProps,
        rowActions,
        noRowsOverlay,
        toolbarIconActions,
        toolbarActions,
        onPaginationModelChange,
        onColumnVisibilityChange,
        onColumnFiltersApply,
        onSortingChange,
        onRowClick,
        variant = 'outlined'
    }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(`${theme.breakpoints.down('sm')}, (max-height:600px)`);
    const [internalPagination, setInternalPagination] = useState({
        pageIndex: 0,
        pageSize: 20
    });
    const [internalColumnVisibility, setInternalColumnVisibility] = useState({});
    const [internalColumnFilters, setInternalColumnFilters] = useState(initialState.columnFilters);
    const [quickFilters, setQuickFilters] = useState({});
    const [showColumnFilters, setShowColumnFilters] = useState(false);
    const [columnFiltersFns, setColumnFilterFns] = useState({});
    const filtersWithoutDefaults = countFiltersWithoutDefaults(internalColumnFilters);
    const [sorting, setSorting] = useState(initialState.sorting ?? []);
    const tableStyles = {
        mobile: {
            muiTablePaperProps: {
                variant,
                elevation: 0
            }
        },
        default: {
            muiTablePaperProps: {
                variant,
                elevation: 0,
                sx: {
                    display: 'flex',
                    flexDirection: 'column',
                    flexGrow: 1
                }
            },
            muiTableContainerProps: {
                sx: {
                    flex: '1 1 0'
                }
            },
            enableStickyFooter: true,
            enableStickyHeader: true,
        }
    };

    useEffect(() => {
        if (onSortingChange) {
            onSortingChange(sorting);
        }
    }, [sorting]);

    const getRowActions = (row) => {
        return typeof rowActions === 'function' ? rowActions(row.original) : rowActions;
    };

    const NoRowsOverlay = ({sx}) => {
        const getStyledIcon = (iconProp) => React.cloneElement(iconProp, {
            sx: {
                fontSize: 64,
                marginBottom: 1
            }
        });

        return <Stack
            sx={{
                ...sx,
                color: 'grey.A400'
            }}
            alignItems="center"
            justifyContent="center"
            direction="column"
        >
            {
                noRowsOverlay === undefined || Object.keys(noRowsOverlay).length === 0
                    ? 'No rows'
                    : <>
                        {
                            noRowsOverlay.icon
                                ? getStyledIcon(noRowsOverlay.icon)
                                : ''
                        }
                        <Typography variant="body1"></Typography>
                        {noRowsOverlay?.text}
                    </>
            }
        </Stack>;
    };

    const handlePaginationChange = updaterOrValue => {
        const old = pagination ?? internalPagination;
        const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(old) : updaterOrValue;
        const func = onPaginationModelChange ?? setInternalPagination;

        func(newValue);
    };

    const handleColumnVisibilityChange = updaterOrValue => {
        const old = columnVisibility ?? internalColumnVisibility;
        const newValue = typeof updaterOrValue === 'function' ? updaterOrValue(old) : updaterOrValue;
        const func = onColumnVisibilityChange ?? setInternalColumnVisibility;

        func(newValue);
    };

    const table = useMaterialReactTable({
        data,
        columns,
        rowCount: totalItemCount,
        state: {
            isLoading,
            showProgressBars: isLoading || isRefetching,
            density: 'comfortable',
            columnPinning: {
                right: ['mrt-row-actions'],
            },
            pagination: pagination ?? internalPagination,
            columnVisibility: columnVisibility ?? internalColumnVisibility,
            columnFilters: internalColumnFilters,
            showColumnFilters,
            columnFilterFns: columnFiltersFns,
            sorting
        },
        enableRowActions: rowActions?.length > 0,
        positionActionsColumn: 'last',
        muiPaginationProps: {
            rowsPerPageOptions: [20, 50, 100]
        },
        onPaginationChange: handlePaginationChange,
        onColumnVisibilityChange: handleColumnVisibilityChange,
        onColumnFiltersChange: setInternalColumnFilters,
        onShowColumnFiltersChange: setShowColumnFilters,
        onSortingChange: setSorting,
        muiTableBodyRowProps: ({row}) => ({
            onClick: (_) => onRowClick
                ? onRowClick(row.original)
                : () => {
                },
            sx: {
                cursor: onRowClick ? 'pointer' : 'default'
            },
        }),
        renderRowActionMenuItems: ({row, closeMenu}) => {
            const actions = getRowActions(row);

            return actions.map(({action, label, disabled}) => (
                <MenuItem
                    key={label}
                    onClick={() => {
                        action(row.original);
                        closeMenu();
                    }}
                    disabled={disabled ? disabled(row.original) : false}
                >
                    {label}
                </MenuItem>
            ));
        },
        renderEmptyRowsFallback: ({table}) =>
            (
                <NoRowsOverlay
                    sx={{
                        height: Math.max(256,
                            (table.refs.tableHeadRef.current?.offsetHeight ?? 0) -
                            (table.refs.tablePaperRef.current?.offsetHeight ?? 0) -
                            (table.refs.topToolbarRef.current?.offsetHeight ?? 0) -
                            (table.refs.bottomToolbarRef.current?.offsetHeight ?? 0) - 8
                        ),
                        maxWidth: table.refs.tablePaperRef.current?.offsetWidth
                    }}>
                </NoRowsOverlay>
            ),
        renderTopToolbarCustomActions: ({table}) => {
            const rows = table.getSelectedRowModel().rows;

            return (
                <Box sx={{display: 'flex', gap: '1rem', p: '4px'}}>
                    {
                        toolbarActions?.map((
                            {
                                label,
                                renderOnRowSelect,
                                enableOnRowSelect = false,
                                type,
                                accessorKey,
                                render,
                                ...rest
                            }, index) => (
                            (renderOnRowSelect && rows.length !== 0) || !renderOnRowSelect
                                ? {
                                button: () => <Button
                                    key={`topToolbarCustomAction_${index}`}
                                    variant="outlined"
                                    disabled={enableOnRowSelect && rows.length === 0}
                                    {...rest}
                                    onClick={async () => {
                                        try {
                                            await Promise.resolve(rest.onClick(rows.map(x => x.original)));
                                        } finally {
                                            if (enableOnRowSelect || renderOnRowSelect) {
                                                table.resetRowSelection();
                                            }
                                        }
                                    }}
                                >
                                    {label}
                                </Button>,
                                select: () => <TextField
                                    select
                                    key={`topToolbarCustomAction_${index}`}
                                    label={label}
                                    size="small"
                                    sx={{
                                        minWidth: 180
                                    }}
                                    onChange={(e) => {
                                        rest.onChange(e.target.value, rows.map(x => x.original));
                                    }}
                                    defaultValue={rest.defaultValue}
                                    value={rest.value}
                                >
                                    {rest.options?.map((opt) => (
                                        <MenuItem
                                            value={opt.value}
                                            key={opt.label}
                                        >
                                            {opt.label}
                                        </MenuItem>
                                    ))}
                                </TextField>,
                                quickFilterSelect: () => <TextField
                                    select
                                    key={`topToolbarCustomAction_${index}`}
                                    label={label}
                                    size="small"
                                    sx={{
                                        minWidth: 180
                                    }}
                                    {...(rest.textFieldProps ? rest.textFieldProps : {})}
                                    onChange={(e) => {
                                        const filters = {...quickFilters, [accessorKey]: e.target.value};

                                        setQuickFilters(filters);
                                        onColumnFiltersApply(internalColumnFilters, filters, columnFiltersFns);
                                    }}
                                    value={quickFilters?.[accessorKey] ?? rest.defaultValue}
                                >
                                    {
                                        render
                                            ? render(rest.options)
                                            : rest.options?.map((opt) => (
                                                <MenuItem
                                                    value={opt.value}
                                                    key={opt.label}
                                                >
                                                    {opt.label}
                                                </MenuItem>
                                            ))
                                    }
                                </TextField>,
                            }[type]?.() ?? ''
                                : ''
                        ))
                    }
                </Box>
            );
        },
        renderToolbarInternalActions: ({table}) => (
            <>
                {
                    table.options.enableFilters
                        ? <MRT_ToggleFiltersButton table={table}/>
                        : ''
                }
                {
                    table.options.enableHiding
                        ? <CustomShowHideMenuItems table={table}/>
                        : ''
                }
                {
                    table.options.enableFullScreenToggle
                        ? <MRT_ToggleFullScreenButton table={table}/>
                        : ''
                }
                {
                    toolbarIconActions?.map(({icon, title, onClick, menuItems, hideIfEmpty, type}, index) => ({
                        button: () => <IconButton
                            key={`toolbarInternalButton_${index}`}
                            onClick={onClick}
                            title={title}
                        >{icon}</IconButton>,
                        select: () => hideIfEmpty && !totalItemCount
                            ? null
                            : <AdmicityIconMenu
                                key={`toolbarInternalButton_${index}`}
                                state={{...table.getState(), quickFilters}}
                                title={title}
                                menuItems={menuItems}
                                icon={icon}
                            />
                    }[type]?.() || null))
                }
            </>
        ),
        displayColumnDefOptions: {
            'mrt-row-actions': {
                muiTableHeadCellProps: {
                    sx: {
                        '&.MuiTableCell-root': {
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
                        },
                    }
                },
                muiTableBodyCellProps: {
                    sx: {
                        '&.MuiTableCell-root': {
                            borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
                        },
                    }
                },
                minSize: 50,
                size: showColumnFilters ? 120 : 50,
                Header: () => <CustomActionsColumn
                    visible={showColumnFilters}
                    filtersCount={filtersWithoutDefaults}
                    onClick={() => {
                        if (onColumnFiltersApply) {
                            onColumnFiltersApply(internalColumnFilters, quickFilters, columnFiltersFns);
                        }
                    }}
                />,
                Cell: ({cell, row, table}) => {
                    return rowActions.length > 0
                        ? <MRT_ToggleRowActionMenuButton cell={cell} row={row} table={table}/>
                        : tableProps.renderRowActions
                            ? tableProps.renderRowActions({row})
                            : '';
                },
            }
        },
        icons: {
            FilterListIcon: props => (<Badge
                badgeContent={filtersWithoutDefaults}
                color="primary">
                <FilterListRoundedIcon {...props}/>
            </Badge>)
        },
        muiFilterTextFieldProps: {
            SelectProps: {
                MenuProps: {
                    sx: {
                        maxWidth: 300,
                        '& .MuiMenuItem-root': {
                            display: 'block',
                            whiteSpace: 'unset',
                            wordBreak: 'break-all',
                            '& .MuiBox-root': {
                                whiteSpace: 'unset',
                                wordBreak: 'break-all'
                            }
                        },
                    }
                },
            }
        },
        positionToolbarAlertBanner: 'none',
        ...baseTableProps,
        ...(isSmallScreen ? tableStyles.mobile : tableStyles.default),
        ...tableProps
    });

    useEffect(() => {
        if (columns?.length > 0) {
            setColumnFilterFns(getFilterFns(columns, table));
        }
    }, [columns]);

    return <MaterialReactTable table={table}/>;
};

AdmicityTableV2.defaultProps = {
    tableProps: {},
    rowActions: [],
};

export default AdmicityTableV2;
