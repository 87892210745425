import {useLocation, useNavigate} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import Button from '@mui/material/Button';
import React from 'react';
import PropTypes from 'prop-types';

const SubmitSuccess = ({navigateBackRoute}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmitAgain = () => navigate(location.pathname + '/../submit-again?form_confirm=true');

    return (
        <Grid
            container
            justifyContent="center"
            spacing={1}
        >
            <Grid item xs={12} sm={2} md={1}>
                <Button
                    variant="text"
                    startIcon={<ArrowBackRoundedIcon/>}
                    onClick={() => navigate(navigateBackRoute)}
                >
                    Back
                </Button>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
                <Typography variant="h5" gutterBottom>Form Submitted Successfully!</Typography>
                <Typography component="p">Click below to submit another response.</Typography>
                <Link
                    sx={{
                        marginTop: 2
                    }}
                    component="button"
                    onClick={handleSubmitAgain}
                >Submit Another Response</Link>
            </Grid>

        </Grid>
    );
};

SubmitSuccess.propTypes = {
    navigateBackRoute: PropTypes.string.isRequired
};

export default SubmitSuccess;
