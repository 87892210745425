import React, {useState} from 'react';
import {Box, Tab, Tabs} from '@mui/material';
import PropTypes from 'prop-types';

const AdmicityTabs = ({tabs, variant, displayDivider, onChange, tabIndex}) => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const handleTabChange = (event, newValue) => {
        if (onChange) {
            onChange(newValue);
            return;
        }

        setCurrentTabIndex(newValue);
    };

    const TabsComponent = <Tabs
        value={onChange ? tabIndex : currentTabIndex}
        onChange={handleTabChange}
        textColor="inherit"
        variant={variant}
    >
        {
            tabs.map((tab, index) =>
                <Tab
                    key={index}
                    value={index}
                    label={tab.label}
                />
            )
        }
    </Tabs>;

    return (
        <>
            {
                displayDivider
                    ? <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        {TabsComponent}
                    </Box>
                    : TabsComponent
            }
            {
                onChange
                    ? tabs[tabIndex].component
                    : tabs[currentTabIndex].component
            }
        </>
    );
};

AdmicityTabs.defaultProps = {
    tabs: [],
    variant: 'standard',
    displayDivider: false
};

AdmicityTabs.propTypes = {
    tabs: PropTypes.array,
    variant: PropTypes.string,
    displayDivider: PropTypes.bool,
    onChange: PropTypes.func,
    tabIndex: PropTypes.number
};

export default AdmicityTabs;