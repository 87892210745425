import React from 'react';
import {useNavigate} from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import {getFormRequestStatusInfo} from '../common/common';
import AdmicityStatus from '../../../../shared-components/AdmicityStatus';
import {Box, Button, Skeleton} from '@mui/material';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import PropTypes from 'prop-types';
import FormBuilder from '../../Forms/FormBuilder/FormBuilder';
import {FORM_REQUEST_STATUSES} from '../../../../constants/formRequestStatuses';
import ErrorIcon from '@mui/icons-material/ErrorOutlineOutlined';

const FormRequest = (
    {
        publicForm,
        generalForm,
        onSubmit,
        formRequest,
        isLoading,
        error,
        navigateBackRoute,
        onDownloadAttachment
    }) => {
    const navigate = useNavigate();

    const handleSubmit = (data, placeholders) => {
        onSubmit(data, placeholders);
    };

    const Header = () =>
        <Box sx={{
            marginLeft: {xs: 4, sm: 6, md: 6, lg: 6},
            marginBottom: isLoading ? 2 : 0
        }}>
            <Typography
                variant="h4"
                component="h1"
                gutterBottom
            >{isLoading ? <Skeleton variant="rounded"/> : formRequest.formName}
            </Typography>
            {
                isLoading
                    ? <Skeleton variant="rounded"/>
                    : generalForm
                        ? null
                        : <AdmicityStatus {...getFormRequestStatusInfo(formRequest.status)}/>
            }

        </Box>;

    const Content = () =>
        isLoading
            ? <Skeleton variant="rounded" height="100%"/>
            : formRequest.schema &&
            <Box sx={{marginTop: 2}}>
                <FormBuilder schema={formRequest.schema}
                             placeholderValues={formRequest.placeholderValues}
                             submitMode={true}
                             handleSubmit={handleSubmit}
                             isParent={formRequest.isParent}
                             handleDownloadAttachment={onDownloadAttachment}
                             data={formRequest.data}
                             readOnly={!publicForm && !generalForm && (formRequest.status === undefined ||
                                 formRequest.status === FORM_REQUEST_STATUSES.submitted ||
                                 formRequest.status !== FORM_REQUEST_STATUSES.sent)
                             }
                />
            </Box>;

    const BackButton = () =>
        <Button
            variant="text"
            startIcon={<ArrowBackRoundedIcon/>}
            onClick={() => navigate(navigateBackRoute)}
        >
            Back
        </Button>;

    const ErrorView = () =>
        error
            ? <Box
                display="flex"
                flexDirection="column"
                flexGrow={1}
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
                marginTop={1}
                marginBottom={1}
            >
                <ErrorIcon color="error" sx={{
                    fontSize: 80,
                    mb: 2,
                    stroke: 'rgb(250, 250, 251)',
                    strokeWidth: 0.5,
                    opacity: 0.7
                }}/>
                <Typography>
                    {error.message}
                </Typography>
            </Box>
            : '';

    return (
        <Grid container
              flexGrow={1}
              minWidth={'100%'}
              maxHeight={'100%'}
        >
            <Grid
                container
                item xs={12}
                overflow="auto"
                justifyContent="center"
            >
                {
                    publicForm
                        ? <Grid item xs={12} sm={10} md={8} lg={6}>
                            {
                                !isLoading && error
                                    ? <ErrorView/>
                                    : <Content/>
                            }
                        </Grid>
                        : <>
                            <Grid
                                item
                                sm={1} md={1} lg={1}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                sx={{
                                    display: {xs: 'none', sm: 'block', md: 'block', lg: 'block'}
                                }}
                            >
                                <BackButton/>
                            </Grid>
                            <Grid item xs={12} sm={10} md={8} lg={6}>
                                <Grid
                                    item
                                    justifyContent="flex-start"
                                    sx={{
                                        display: {xs: 'block', sm: 'none', md: 'none', lg: 'none'}
                                    }}
                                >
                                    <BackButton/>
                                </Grid>
                                {
                                    !isLoading && error
                                        ? <ErrorView/>
                                        : <>
                                            <Header/>
                                            <Content/>
                                        </>
                                }
                            </Grid>
                        </>
                }
            </Grid>
        </Grid>
    );
};

FormRequest.propTypes = {
    publicForm: PropTypes.bool,
    generalForm: PropTypes.bool,
    onSubmit: PropTypes.func,
    onDownloadAttachment: PropTypes.func,
    formRequest: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    error: PropTypes.object,
    navigateBackRoute: PropTypes.string.isRequired
};

FormRequest.defaultProps = {
    publicForm: false,
    generalForm: false
};

export default FormRequest;