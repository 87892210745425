import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Container} from '@mui/material';
import TravelMode from './TravelMode';
import PermissionCheck from '../../Components/PermissionCheck';
import Consents from './Consents';
import {useParams} from 'react-router-dom';
import {retrieveProfile} from '../../ProfilesSlice';

const ConsentsTab = () => {
    const {studentId} = useParams();
    const selectedProfile = useSelector(state => state.profilesInfo.selectedProfile);
    const currentSchool = useSelector(state => state.profilesInfo.currentSchool);
    const dispatch = useDispatch();

    return (
        <Container
            xs={12}
            sm={12}
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <PermissionCheck
                permissionModule={selectedProfile.isApplicant ? 'ApplicantConsents' : 'StudentConsents'}
                render={(readonly) => <Consents
                    readonly={readonly}
                    school={currentSchool}
                    profile={selectedProfile}
                    onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                />}
            />
            <PermissionCheck
                permissionModule={selectedProfile.isApplicant ? 'ApplicantTravelMode' : 'StudentTravelMode'}
                render={(readonly) => <TravelMode
                    readonly={readonly}
                    school={currentSchool}
                    profile={selectedProfile}
                    onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                />}
            />
        </Container>
    );
};

export default ConsentsTab;
