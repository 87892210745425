import React, {useEffect, useState} from 'react';
import {
    CircularProgress,
    Container,
    Divider,
    styled,
} from '@mui/material';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {useGetMedicalConsentOptionsQuery} from '../../../../../api/services/lookupsService';
import {useGetMedicalInfoQuery, useManageMedicalSectionMutation} from '../../../../../api/services/studentService';
import useFileDownloader from '../../../../../utility/hooks/useFileDownloader';
import {useDispatch, useSelector} from 'react-redux';
import {retrieveProfile} from '../../ProfilesSlice';
import Uploader from '../../../../SchoolManagement/Setup/Uploader';
import AdmicityDialog from '../../../../../shared-components/AdmicityDialog';
import {generateUUID} from '../../../../../utility/uuidUtil';
import MedicalEvents from './MedicalEvents';
import MedicalConditions from './MedicalConditions';
import MedicalNotes from './MedicalNotes';
import MedicalOtc from './MedicalOtc';
import GpInfo from './GpInfo';
import PermissionCheck from '../../Components/PermissionCheck';

const MedicalFields = styled(Container)({
    display: 'flex', flexDirection: 'column', justifyContent: 'start'
});

const MedicalInfoTab = () => {
    const [rows, setRows] = useState([]);
    const [eventRows, setEventRows] = useState([]);
    const [noteRows, setNoteRows] = useState([]);
    const {studentId} = useParams();
    const [isVaccinationDeleteClicked, setIsVaccinationDeleteClicked] = useState(false);
    const [vaccinationFile, setVaccinationFile] = useState(null);
    const [vaccinationFileToDelete, setVaccinationFileToDelete] = useState(null);
    const [gpDetails, setGpDetails] = useState('');
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [deselectedOptions, setDeselectedOptions] = useState([]);
    const [isFileSizeError, setIsFileSizeError] = useState(false);
    const [medicalConditionStatus, setMedicalConditionStatus] = useState(undefined);
    const {selectedProfile, currentSchool} = useSelector(state => state.profilesInfo);
    const dispatch = useDispatch();
    const [openAddConditionDocumentsDialog, setOpenAddConditionDocumentsDialog] = useState(false);
    const [openAddEventDocumentsDialog, setOpenAddEventDocumentsDialog] = useState(false);
    const [openAddNoteDocumentsDialog, setOpenAddNoteDocumentsDialog] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [isUploadSizeExceedsLimit, setIsUploadSizeExceedsLimit] = useState(false);
    const [targetConditionId, setTargetConditionId] = useState(undefined);
    const [totalUploadFilesSize, setTotalUploadFilesSize] = useState(25);
    const [isEventsReadonly, setIsEventsReadonly] = useState(false);
    const [isNotesReadonly, setIsNotesReadonly] = useState(false);
    const [manageMedicalSection] = useManageMedicalSectionMutation();

    const {
        data: medicalInfo = {options: []},
        isLoading: isMedicalInfoLoading,
        refetch: refetchMedicalInfoData
    } = useGetMedicalInfoQuery(studentId);

    const {
        data: medicalConsentOptions = {options: []},
        isLoading: isMedicalConsentOptionsLoading,
        refetch: refetchMedicalConsentsData
    } = useGetMedicalConsentOptionsQuery();

    const refetchData = async () => {
        await refetchMedicalInfoData();
        await refetchMedicalConsentsData();
    };
    const downloadFile = useFileDownloader();

    useEffect(() => {
        if (!isMedicalInfoLoading) {
            setMedicalConditionStatus(medicalInfo.medicalConditionStatus);
            setRows(medicalInfo.medicalConditions);
            setEventRows(medicalInfo.medicalEvents);
            setNoteRows(medicalInfo.medicalNotes);
            setGpDetails(medicalInfo.gpInfo);
            setSelectedOptions(medicalInfo.medicalConsentOptions?.options.map(x => x.optionId));
        }
    }, [isMedicalInfoLoading, medicalInfo]);

    const handleDialogClose = () => {
        setOpenAddConditionDocumentsDialog(false);
        setOpenAddNoteDocumentsDialog(false);
        setOpenAddEventDocumentsDialog(false);
        setSelectedFiles([]);
        setIsUploading(false);
        setIsUploadSizeExceedsLimit(false);
        setTargetConditionId(undefined);
    };

    const updateMedicalEvents = (updatedValue, isDelete = false) => {
        if (isDelete && updatedValue.isNew) {
            setEventRows(eventRows.filter(e => updatedValue.id ? e.id !== updatedValue.id : e.uuid !== updatedValue.uuid));
            return;
        }

        if (!updatedValue.id && !updatedValue.uuid) {
            updatedValue.uuid = generateUUID();
            updatedValue.isNew = true;
            setEventRows([updatedValue, ...eventRows]);
            return;
        }

        const updatedRows = eventRows.map(row => {
            if (updatedValue.id ? row.id === updatedValue.id : row.uuid === updatedValue.uuid) {
                return {
                    ...row,
                    ...updatedValue,
                    isEdited: !updatedValue.isNew,
                    isDeleted: isDelete,
                    documents: updatedValue.documents
                };
            }
            return row;
        });

        setEventRows(updatedRows);
        setSelectedFiles([]);
    };

    const updateMedicalNotes = (updatedValue, isDelete = false) => {
        if (isDelete && updatedValue.isNew) {
            setNoteRows(noteRows.filter(e => updatedValue.id ? e.id !== updatedValue.id : e.uuid !== updatedValue.uuid));
            return;
        }

        if (!updatedValue.id && !updatedValue.uuid) {
            updatedValue.uuid = generateUUID();
            updatedValue.isNew = true;
            setNoteRows([updatedValue, ...noteRows]);
            return;
        }

        const updatedRows = noteRows.map(row => {
            if (updatedValue.id ? row.id === updatedValue.id : row.uuid === updatedValue.uuid) {
                return {
                    ...row,
                    ...updatedValue,
                    isEdited: !updatedValue.isNew,
                    isDeleted: isDelete,
                };
            }
            return row;
        });

        setNoteRows(updatedRows);
        setSelectedFiles([]);
    };

    const handleConditionDocumentsUploaded = async () => {
        setIsUploading(true);
        const updatedRows = rows.map(row => ({
            ...row,
            medicalCondition: row.medicalCondition
                ? {
                    ...row.medicalCondition,
                    documents: row.medicalCondition.documents
                        ? row.medicalCondition.documents.map(document => ({
                            ...document
                        }))
                        : []
                }
                : null
        }));

        const targetCondition = updatedRows.find(row => row.id === targetConditionId);

        if (targetCondition) {
            targetCondition.isEdited = true;

            const newFiles = selectedFiles.map(file => ({
                file,
                summary: '',
                note: '',
                name: file.name,
                isActive: true
            }));

            targetCondition.medicalCondition.documents = [
                ...newFiles,
                ...(targetCondition.medicalCondition.documents || [])
            ];

            setTotalUploadFilesSize(totalUploadFilesSize - selectedFiles.reduce((total, file) => {
                return total + file.size;
            }, 0) / (1024 * 1024));
        }

        setRows(updatedRows);
        setTargetConditionId(undefined);
        handleDialogClose();
    };

    const handleSelectFiles = (files) => {
        const newDocuments = files.filter(file => !selectedFiles.some(s => s.name === file.name));
        setSelectedFiles([...selectedFiles, ...newDocuments]);
    };

    const handleSelectFile = (files) => {
        setSelectedFiles([...files]);
    };

    const handleFileDeleteFromUploadContext = (filename) => {
        const files = [...selectedFiles];
        setSelectedFiles(files.filter(f => f.name !== filename));
    };

    const updateMedicalCondition = async ({
        summary,
        note,
        isActive,
        conditionId,
        documentId,
        filename,
        isDeleteDocument
    }) => {
        const updatedRows = rows.map(row => ({
            ...row,
            medicalCondition: row.medicalCondition
                ? {
                    ...row.medicalCondition,
                    documents: row.medicalCondition.documents
                        ? row.medicalCondition.documents.map(document => ({
                            ...document
                        }))
                        : []
                }
                : null
        }));

        const targetCondition = updatedRows.find(row => row.id === conditionId);

        if (!targetCondition) return;

        targetCondition.isEdited = true;

        if (targetCondition.medicalCondition) {
            const conditionDocumentToUpdate = documentId
                ? targetCondition.medicalCondition.documents.find(file => file.id === documentId)
                : targetCondition.medicalCondition.documents.find(file => file.name === filename);

            if (conditionDocumentToUpdate) {
                if (note !== undefined) {
                    conditionDocumentToUpdate.note = note;
                }
                if (summary !== undefined) {
                    conditionDocumentToUpdate.summary = summary;
                }
                if (isDeleteDocument !== undefined && isDeleteDocument !== null) {
                    if (!conditionDocumentToUpdate.id && (!targetCondition.isEdited || targetCondition.isEdited === false)) {
                        targetCondition.medicalCondition.documents = targetCondition.medicalCondition.documents.filter(
                            (doc) => doc !== conditionDocumentToUpdate
                        );

                        setRows(prevRows =>
                            prevRows.map(row =>
                                row.id === targetCondition.id
                                    ? {
                                        ...row,
                                        medicalCondition: {
                                            ...row.medicalCondition,
                                            documents: row.medicalCondition.documents.filter(doc => doc !== conditionDocumentToUpdate)
                                        }
                                    }
                                    : row
                            )
                        );
                    } else {
                        conditionDocumentToUpdate.isActive = false;
                        conditionDocumentToUpdate.isDeleted = true;

                        if (conditionDocumentToUpdate.file) {
                            setTotalUploadFilesSize(totalUploadFilesSize + (conditionDocumentToUpdate.file.size / 1024 / 1024));
                        }
                    }
                }
            }

            if (isActive !== undefined) {
                if (isActive) {
                    targetCondition.medicalCondition.isActive = isActive;
                } else {
                    const originalCondition = medicalInfo.medicalConditions.find(e => e.id === conditionId);

                    setTotalUploadFilesSize(totalUploadFilesSize + targetCondition.medicalCondition.documents.reduce((total, document) => {
                        if (!document.file) {
                            return total;
                        }

                        return total + document.file.size;
                    }, 0) / (1024 * 1024));

                    if (originalCondition?.medicalCondition) {
                        targetCondition.medicalCondition.isActive = isActive;
                    } else {
                        delete targetCondition.isEdited;
                        targetCondition.medicalCondition = null;
                    }
                }
            }
        } else {
            targetCondition.medicalCondition = {isActive: true};
        }
        setRows(updatedRows);
    };

    const handleVaccinationFileUploaded = (file) => {
        if (!file) return;

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 15) {
            setIsFileSizeError(true);
            setVaccinationFile({name: file.name});
        } else {
            setIsFileSizeError(false);
            setVaccinationFile(file);
        }

        if (medicalInfo.vaccination?.fileId) {
            setVaccinationFileToDelete(medicalInfo.vaccination?.fileId);
        }

        setIsVaccinationDeleteClicked(false);
    };

    const handleVaccinationFileDelete = async (fileId) => {
        setIsVaccinationDeleteClicked(true);
        if (vaccinationFile !== null) {
            setVaccinationFile(null);
        } else {
            setVaccinationFileToDelete(fileId);
        }
        setIsFileSizeError(false);
    };

    const handleCheckMedicalConsents = (event) => {
        const {checked, value} = event.target;
        const optionId = parseInt(value);

        if (checked) {
            setSelectedOptions([...selectedOptions, optionId]);
            setDeselectedOptions(deselectedOptions.filter(id => id !== optionId));
        } else {
            setDeselectedOptions([...deselectedOptions, optionId]);
            setSelectedOptions(selectedOptions.filter(id => id !== optionId));
        }
    };

    const handleSave = async (confirmationType) => {
        const updatedRows = rows
            .filter(row => row.isEdited)
            .map(row => {
                return {
                    name: row.name,
                    medicalInfoId: row.id,
                    medicalConditionId: row.medicalCondition.id,
                    ...row.medicalCondition,
                    documents: row.medicalCondition.documents?.filter(doc => doc.id || !doc.isDeleted)
                };
            });

        const payload = {
            studentId,
            confirmationType,
            medicalConditions: updatedRows,
            medicalNotesToDelete: noteRows.filter(e => e.isDeleted).map(e => e.id),
            medicalNotesToAdd: noteRows.filter(e => e.isNew).map(e => ({
                file: e.file,
                note: e.note,
                summary: e.summary
            })),
            medicalNotesToEdit: noteRows.filter(e => e.isEdited && !e.isDeleted).map(e => ({
                id: e.id,
                file: e.file,
                fileId: e.fileId,
                note: e.note,
                summary: e.summary
            })),
            medicalEventsToDelete: eventRows.filter(e => e.isDeleted).map(e => e.id),
            medicalEventsToAdd: eventRows.filter(e => e.isNew).map(e => ({
                eventId: e.eventId,
                eventTypeId: e.eventTypeId,
                followUpDate: e.followUpDate,
                eventDate: e.eventDate,
                documents: e.documents?.map(d => ({
                    file: d.file,
                    note: d.note,
                    summary: d.summary
                }))
            })),
            medicalEventsToEdit: eventRows.filter(e => e.isEdited && !e.isDeleted).map(e => ({
                eventId: e.eventId,
                eventTypeId: e.eventTypeId,
                followUpDate: e.followUpDate,
                eventDate: e.eventDate,
                id: e.id,
                documents: e.documents.map(d => ({
                    file: d.file,
                    fileId: d.fileId,
                    note: d.note,
                    summary: d.summary,
                    id: d.id
                }))
            })),
            vaccination: vaccinationFile,
            medicalConditionStatus,
            gpDetails,
            deletedVaccinationFileId: vaccinationFileToDelete,
            selectedConsents: selectedOptions
                .filter(x => !medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x)),
            deselectedConsents: deselectedOptions
                .filter(x => medicalInfo.medicalConsentOptions?.options.some(v => v.optionId === x))
        };
        await manageMedicalSection(payload).then(() => dispatch(retrieveProfile({studentId: selectedProfile.id})));
        setVaccinationFile(null);
        setVaccinationFileToDelete(null);
        setTotalUploadFilesSize(25);
    };

    return (
        isMedicalInfoLoading || isMedicalConsentOptionsLoading
            ? ''
            : <MedicalFields xs={12} sm={6} px={3}>

                <Typography variant="h5" py={2}>MEDICAL INFORMATION
                        FOR {selectedProfile.name?.toUpperCase()}</Typography>
                <Typography fontWeight={'bold'}>Please take care to complete this section of {selectedProfile.name}’s
                        Student Profile accurately. It is
                        your responsibility to ensure that it is correct and up-to-date at all times. The information
                        you provide will be relied upon by school staff and any other person or organisation who may
                        have {selectedProfile.name} in their care on behalf of {currentSchool.name}.</Typography>

                <Divider sx={{mt: 2, mb: 2}}/>

                <PermissionCheck
                    permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalConditions' : 'StudentMedicalConditions'}
                    returnType="readonly"
                    render={(isView) => {
                        return (
                            <MedicalConditions
                                readonly={isView}
                                onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                                rows={rows}
                                medicalConditionStatus={medicalConditionStatus}
                                setMedicalConditionStatus={setMedicalConditionStatus}
                                selectedProfile={selectedProfile}
                                currentSchool={currentSchool}
                                updateMedicalCondition={updateMedicalCondition}
                                setTargetConditionId={setTargetConditionId}
                                setOpenAddConditionDocumentsDialog={setOpenAddConditionDocumentsDialog}
                                downloadFile={downloadFile}
                                medicalInfo={medicalInfo}
                                studentId={studentId}
                                handleSave={handleSave}
                                refetchData={refetchData}
                                isEdit={!isView}
                            />
                        )
                    }
                    }
                />

                <PermissionCheck
                    permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalEvents' : 'StudentMedicalEvents'}
                    returnType="readonly"
                    render={(isView) => {
                        setIsEventsReadonly(isView);
                        return (
                            <MedicalEvents
                                readonly={isView}
                                medicalEvents={eventRows}
                                downloadFile={downloadFile}
                                studentId={studentId}
                                isUploading={isUploading}
                                selectedFiles={selectedFiles}
                                isUploadSizeExceedsLimit={isUploadSizeExceedsLimit}
                                totalUploadFilesSize={totalUploadFilesSize}
                                handleSelectFiles={handleSelectFiles}
                                handleFileDeleteFromUploadContext={handleFileDeleteFromUploadContext}
                                setIsUploadSizeExceedsLimit={setIsUploadSizeExceedsLimit}
                                handleDialogClose={handleDialogClose}
                                updateMedicalEvents={updateMedicalEvents}
                                setTotalUploadFilesSize={setTotalUploadFilesSize}
                                setIsUploading={setIsUploading}
                                medicalInfo={medicalInfo}
                                refetchData={refetchData}
                                eventRows={eventRows}
                                handleSave={handleSave}
                                isEventsReadonly={isEventsReadonly}
                            />
                        )
                    }}
                />

                <PermissionCheck
                    permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalNotes' : 'StudentMedicalNotes'}
                    returnType={'readonly'}
                    render={(isView) => {
                        setIsNotesReadonly(isView)
                        return (<MedicalNotes
                            noteRows={noteRows}
                            refetchData={refetchData}
                            readonly={isView}
                            medicalInfo={medicalInfo}
                            medicalNotes={noteRows}
                            downloadFile={downloadFile}
                            studentId={studentId}
                            isUploading={isUploading}
                            selectedFiles={selectedFiles}
                            isUploadSizeExceedsLimit={isUploadSizeExceedsLimit}
                            totalUploadFilesSize={totalUploadFilesSize}
                            handleSelectFiles={handleSelectFile}
                            handleFileDeleteFromUploadContext={handleFileDeleteFromUploadContext}
                            setIsUploadSizeExceedsLimit={setIsUploadSizeExceedsLimit}
                            handleDialogClose={handleDialogClose}
                            updateMedicalNotes={updateMedicalNotes}
                            setTotalUploadFilesSize={setTotalUploadFilesSize}
                            setIsUploading={setIsUploading}
                            handleSave={handleSave}
                            rows={noteRows}
                            medicalConditionStatus={medicalConditionStatus}
                            isNotesReadonly={isNotesReadonly}
                        />
                        )
                    }
                    }
                />
                <PermissionCheck
                    permissionModule={selectedProfile.isApplicant ? 'ApplicantMedicalConsents' : 'StudentMedicalConsents'}
                    returnType={'readonly'}
                    render={(isView) => {

                        return (<MedicalOtc
                            readOnly={isView}
                            refetchData={refetchData}
                            medicalInfo={medicalInfo}
                            studentId={studentId}
                            medicalConsentOptions={medicalConsentOptions}
                            handleSave={handleSave}
                            handleCheckMedicalConsents={handleCheckMedicalConsents}
                            selectedOptions={selectedOptions}
                            deselectedOptions={deselectedOptions}
                            isEdit={!isView}
                        />)
                    }
                    }
                />
                <PermissionCheck
                    permissionModule={selectedProfile.isApplicant ? 'ApplicantGpInfo' : 'StudentGpInfo'}
                    returnType={'readonly'}
                    render={(isView) => {
                        return (<GpInfo
                            readOnly={isView}
                            setGpDetails={setGpDetails}
                            refetchData={refetchData}
                            medicalInfo={medicalInfo}
                            studentId={studentId}
                            gpDetails={gpDetails}
                            vaccinationFile={vaccinationFile}
                            vaccinationFileToDelete={vaccinationFileToDelete}
                            handleVaccinationFileUploaded={handleVaccinationFileUploaded}
                            isVaccinationDeleteClicked={isVaccinationDeleteClicked}
                            handleVaccinationFileDelete={handleVaccinationFileDelete}
                            handleSave={handleSave}
                            isFileSizeError={isFileSizeError}
                            downloadFile={downloadFile}
                            isEdit={!isView}
                        />
                        )
                    }
                    }
                />
                <AdmicityDialog
                    handleClose={handleDialogClose}
                    title={'Add Documents'}
                    open={openAddNoteDocumentsDialog || openAddConditionDocumentsDialog || openAddEventDocumentsDialog}
                    actions={[
                        {label: 'Cancel', onClick: handleDialogClose},
                        {
                            label: 'Upload',
                            onClick: handleConditionDocumentsUploaded,
                            disabled: isUploading || selectedFiles?.length === 0 || isUploadSizeExceedsLimit,
                            startIcon: isUploading
                                ? <CircularProgress color="inherit" size={16}/>
                                : undefined
                        }
                    ]}
                    maxWidth={'md'}
                >
                    <Uploader
                        totalUploadSizeInMb={totalUploadFilesSize}
                        onFilesSelect={handleSelectFiles}
                        onFileDelete={handleFileDeleteFromUploadContext}
                        onUploadLimitExceed={setIsUploadSizeExceedsLimit}
                        value={selectedFiles}
                        readonly={isUploading}
                    />
                </AdmicityDialog>

            </MedicalFields>
    );
};

export default MedicalInfoTab;
