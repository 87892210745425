import EthnicityFields from './EthnicityFields';
import PermissionCheck from '../../Components/PermissionCheck';
import React from 'react';
import Contacts from './Contacts';
import {useSelector} from 'react-redux';

const ContactsAndEthnicityTab = () => {
    const isPersonApplicant = useSelector(state => state.profilesInfo.selectedProfile.isApplicant);

    return (
        <>
            <PermissionCheck
                permissionModule={isPersonApplicant ? 'ApplicantContacts' : 'StudentContacts'}
                render={(readonly) => <Contacts readonly={readonly}/>}
            />
            <PermissionCheck
                permissionModule={isPersonApplicant ? 'ApplicantEthnicity' : 'StudentEthnicity'}
                render={(readonly) => <EthnicityFields readonly={readonly}/>}
            />
        </>
    );
}

export default ContactsAndEthnicityTab;