import React from 'react';
import {Box, Checkbox, Divider, Typography, Button, Accordion, AccordionDetails, AccordionSummary, IconButton, TextField, styled} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import UploadFile from '@mui/icons-material/UploadFile';
import {MEDICAL_CONDITION_STATUSES} from '../../const/MedicalConditionStatus';
import ConfirmationWrapper from '../../Components/ConfirmationWrapper';
import {STUDENT_PROFILE_CONFIRMATION_TYPES} from '../../const/StudentProfileConfirmationTypes';
import {downloadProfileMedicalConditionDocument} from '../../../../../api/services/filesService';
import {Item} from '../DietaryWelfareEthnicityTab/FreeSchoolMealsFields';
import {FileDownload} from '@mui/icons-material';

/* eslint-disable react/prop-types */
const MedicalConditions = ({
    medicalConditionStatus,
    rows,
    selectedProfile,
    currentSchool,
    updateMedicalCondition,
    setTargetConditionId,
    setOpenAddConditionDocumentsDialog,
    downloadFile,
    isEdit,
    setMedicalConditionStatus,
    medicalInfo,
    studentId,
    handleSave,
    refetchData,
    readonly
}) => {

    const getHisHerPronoun = () => {
        if (!selectedProfile.gender) {
            return 'his/her';
        }
        return selectedProfile.gender === 'M' ? 'his' : 'her';
    }

    const DownloadIcon = styled(FileDownload)(({theme}) => ({
        color: theme.palette.primary.main, cursor: 'pointer'
    }));

    const MedicalRow = styled(Box)({
        display: 'flex', justifyContent: 'space-between', alignItems: 'center'
    });

    const isNothingChanged = () => {
        const isMedicalRowsInitial = rows?.filter(row => row.isEdited).length === 0;
        const isMedicalStatusInitial = medicalConditionStatus === medicalInfo.medicalConditionStatus;

        return isMedicalRowsInitial && isMedicalStatusInitial;
    };

    const isMedicalConditionStatus = medicalConditionStatus !== '' && medicalConditionStatus !== undefined;
    const hasMedicalCondition = medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION;
    const ifMedicalConditionIsActive = rows?.some(row => row?.medicalCondition?.isActive);
    const isMedicalConditionEdited = rows?.some(row => row?.isEdited);

    // Has a summary if a document is added
    const hasMedicalConditionDocumentSummary = () => {
        const hasMedicalConditionDocument = rows?.some(row => row?.medicalCondition?.documents?.length > 0);
        const allDocumentsHaveSummary = rows?.every(row =>
            !row?.medicalCondition?.documents ||
            row.medicalCondition.documents.every(doc =>
                doc.isDeleted || (doc.summary && doc.summary.trim() !== '')
            )
        );
        return !hasMedicalConditionDocument || allDocumentsHaveSummary;
    };

    // validate confirmation medical condition status
    const otherMedicalConditionStatus =
    medicalConditionStatus !== '' &&
    (medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION ||
     medicalConditionStatus === MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED);

    const saveHasMedicalCondition = () => {
        return hasMedicalCondition && isMedicalConditionEdited && ifMedicalConditionIsActive;
    };

    return (
        <>
            <ConfirmationWrapper
                readonly={readonly}
                confirmData={{
                    confirmMessage: `I confirm that the information in the medical profile section of ${selectedProfile.name} ${selectedProfile.surname} student profile is correct and understand it will be relied upon by school staff and any other person or organisation who may have ${selectedProfile.name} ${selectedProfile.surname} in their care on behalf of ${currentSchool.name}`,
                    confirmedAt: medicalInfo?.confirmedAt,
                    confirmedBy: medicalInfo?.confirmedBy,
                    enableSaveButton: !isNothingChanged() && isMedicalConditionStatus && hasMedicalConditionDocumentSummary() && (saveHasMedicalCondition() || otherMedicalConditionStatus),

                    enableConfirmButton: ((ifMedicalConditionIsActive && hasMedicalCondition) && hasMedicalConditionDocumentSummary() && !isMedicalConditionEdited) || otherMedicalConditionStatus,
                    studentId,
                    type: STUDENT_PROFILE_CONFIRMATION_TYPES?.Medical,
                    handleSave,
                    onConfirmSuccess: async () => await refetchData(),
                    confirmButtonText: 'Confirm Medical Conditions'
                }}
            >
                <Typography variant="h5" pb={2}>Medical Conditions</Typography>
                {!medicalConditionStatus &&
                                                   <Typography>Please tick one of the following boxes:</Typography>}
                {
                    medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION && medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED &&
                                                   <Item>
                                                       <Typography>{selectedProfile.name} does not have any medical conditions
                                                           that {currentSchool.name} need to be aware of.</Typography>
                                                       <Checkbox
                                                           sx={{width: '25px', height: '25px'}}
                                                           value={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION}
                                                           checked={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION}
                                                           disabled={!isEdit || rows?.some(row => row.medicalCondition && row.medicalCondition.isActive)}
                                                           onChange={event => setMedicalConditionStatus(event.target.checked ? MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION : undefined)}
                                                       />
                                                   </Item>
                }
                {
                    medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED && medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION &&
                                                   <Item>
                                                       <Typography>{selectedProfile.name} has one or more medical conditions that have been
                                                           formally
                                                           diagnosed by an appropriately qualified professional.</Typography>
                                                       <Checkbox
                                                           sx={{width: '25px', height: '25px'}}
                                                           value={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION}
                                                           checked={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION}
                                                           disabled={!isEdit || rows?.some(row => row.medicalCondition && row.medicalCondition.isActive)}
                                                           onChange={event => setMedicalConditionStatus(event.target.checked ? MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION : undefined)}
                                                       />
                                                   </Item>
                }
                {medicalConditionStatus === MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION && <>
                    <Typography pb={2}>
                                                       Please tick {selectedProfile.name}’s medical condition(s).
                                                       If {selectedProfile.name} has a medical
                                                       condition does not appear in the list please select Other/Any Other Medical
                                                       Condition. You
                                                       should use the &apos;upload&apos; button to provide supplementary documentation -
                                                       for example a
                                                       diagnosis letter or a management plan related to the condition. The Summary field
                                                       that
                                                       appears beneath the uploaded document should be used to describe the type of
                                                       document being
                                                       uploaded. The Notes field below the Summary can be used to provide additional
                                                       details and
                                                       information you would like {medicalInfo.schoolName} to know
                                                       about {selectedProfile.name}’s medical condition,
                                                       for example, the type and dose of medication required and whether it is taken during
                                                       the
                                                       school day.</Typography>
                    {rows?.map((condition) => {
                        return (
                            <Box key={condition.name}>
                                <MedicalRow>
                                    <Typography>{condition.name}</Typography>
                                    <Checkbox
                                        sx={{width: '25px', height: '25px'}}
                                        checked={!!condition.medicalCondition && condition.medicalCondition.isActive}
                                        onChange={event => updateMedicalCondition({
                                            isActive: event.target.checked,
                                            conditionId: condition.id
                                        })}
                                        disabled={!isEdit}
                                    />
                                </MedicalRow>
                                {condition.medicalCondition && condition.medicalCondition.isActive
                                    ? <>

                                        <Box py={1} display="flex" alignItems="center">
                                            {
                                                !isEdit
                                                    ? ''
                                                    : <Button
                                                        variant="contained"
                                                        startIcon={<UploadFile/>}
                                                        onClick={() => {
                                                            setTargetConditionId(condition.id);
                                                            setOpenAddConditionDocumentsDialog(true);
                                                        }}
                                                    >
                                                                                           Upload Documents
                                                    </Button>
                                            }
                                        </Box>
                                        {
                                            condition?.medicalCondition?.documents
                                                ?.filter(e => e.isActive)
                                                .map((file, index) => {
                                                    return <>
                                                        <Box key={file.id ?? `document-${condition.id}-${index}`} display={'flex'} alignItems={'center'} py={1}>
                                                            <Accordion sx={{width: '100%'}}
                                                                defaultExpanded={!file.fileId}>
                                                                <AccordionSummary
                                                                    expandIcon={<ArrowDropDownIcon/>}>
                                                                    <Box display="flex"
                                                                        justifyContent="space-between"
                                                                        alignItems="center"
                                                                        sx={{
                                                                            maxWidth: {
                                                                                xs: '250px',
                                                                                sm: '395px',
                                                                                md: '695px',
                                                                                lg: '995px',
                                                                                xl: '1050px',
                                                                            }
                                                                        }}>
                                                                        < Typography
                                                                            sx={{
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis',
                                                                                whiteSpace: 'nowrap',
                                                                                flexGrow: 1,
                                                                                marginRight: '8px',
                                                                            }}
                                                                        >
                                                                            {file?.name}
                                                                        </Typography>
                                                                        {file.fileId && (
                                                                            <IconButton
                                                                                sx={{
                                                                                    flexShrink: 0,
                                                                                }}
                                                                                onClick={async (e) => {
                                                                                    e.stopPropagation()
                                                                                    await downloadFile(downloadProfileMedicalConditionDocument, {
                                                                                        documentId: file.fileId,
                                                                                        studentId
                                                                                    })
                                                                                }}
                                                                            >
                                                                                <DownloadIcon/>
                                                                            </IconButton>
                                                                        )}
                                                                    </Box>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <TextField
                                                                        fullWidth
                                                                        label={'Summary'}
                                                                        required
                                                                        value={file.summary}
                                                                        onChange={event => updateMedicalCondition({
                                                                            summary: event.target.value,
                                                                            conditionId: condition.id,
                                                                            documentId: file.id,
                                                                            filename: file?.name
                                                                        })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                    <TextField
                                                                        sx={{my: 2}}
                                                                        multiline
                                                                        fullWidth
                                                                        label={'Note'}
                                                                        value={file.note}
                                                                        onChange={event => updateMedicalCondition({
                                                                            note: event.target.value || '',
                                                                            conditionId: condition.id,
                                                                            documentId: file.id,
                                                                            filename: file?.name
                                                                        })}
                                                                        disabled={!isEdit}
                                                                    />
                                                                </AccordionDetails>
                                                            </Accordion>
                                                            {
                                                                isEdit && <IconButton
                                                                    onClick={() => updateMedicalCondition({
                                                                        conditionId: condition.id,
                                                                        documentId: file.id,
                                                                        filename: file?.name,
                                                                        isDeleteDocument: true
                                                                    })}>
                                                                    <DeleteIcon/>
                                                                </IconButton>
                                                            }
                                                        </Box>

                                                    </>
                                                }
                                                )
                                        }
                                    </>
                                    : <></>
                                }
                            </Box>
                        );
                    }
                    )}
                </>
                }
                {
                    medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITH_MEDICAL_CONDITION && medicalConditionStatus !== MEDICAL_CONDITION_STATUSES.WITHOUT_MEDICAL_CONDITION &&
                                                   <Item>
                                                       <Typography>{selectedProfile.name} has a suspected medical condition that has not
                                                           yet been
                                                           formally diagnosed.</Typography>
                                                       <Checkbox
                                                           sx={{width: '25px', height: '25px'}}
                                                           value={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED}
                                                           checked={medicalConditionStatus === MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED}
                                                           disabled={!isEdit || rows?.some(row => row.medicalCondition && row.medicalCondition.isActive)}
                                                           onChange={event => setMedicalConditionStatus(event.target.checked ? MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED : undefined)}
                                                       />
                                                   </Item>
                }
                {
                    medicalConditionStatus === MEDICAL_CONDITION_STATUSES.NOT_YET_DIAGNOSED &&
                                                   <Typography variant={'body2'}>Please use the Medical Notes section of this page to
                                                       provide details and upload any
                                                       supplementary documentation. If Medical Notes is not visible on this page, please
                                                       use the Documentation tab of {selectedProfile.name}&apos;s student profile to upload information
                                                       about {getHisHerPronoun()} medical condition.</Typography>
                }
            </ConfirmationWrapper>
            <Divider sx={{mt: 2, mb: 2}}/>
        </>
    );
};
export default MedicalConditions;
