import {useEffect} from 'react';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';

const SubmitRedirect = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('form_confirm') === 'true') {
            navigate(location.pathname + '/../', {replace: true});
        }
    }, [location, navigate]);

    return null;
};

export default SubmitRedirect;
