import React from 'react';
import DietaryNeeds from './DietaryNeeds';
import {useParams} from 'react-router-dom';
import {Container} from '@mui/material';
import {retrieveProfile} from '../../ProfilesSlice';
import {useDispatch, useSelector} from 'react-redux';
import PermissionCheck from '../../Components/PermissionCheck';
import WelfareOptions from './WelfareOptions';
import FreeSchoolMealsFields from './FreeSchoolMealsFields';

const DietaryWelfareEthnicityTab = () => {
    const {studentId} = useParams();
    const dispatch = useDispatch();
    const isPersonApplicant = useSelector(state => state.profilesInfo.selectedProfile.isApplicant);

    return (
        <Container
            xs={12}
            sm={12}
            sx={{
                display: 'flex',
                flexDirection: 'column'
            }}
        >
            <PermissionCheck
                permissionModule={isPersonApplicant ? 'ApplicantDietaryNeedsAndFoodAllergies' : 'StudentDietaryNeedsAndFoodAllergies'}
                render={(readonly) => <DietaryNeeds
                    readonly={readonly}
                    onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                />}
            />
            <PermissionCheck
                permissionModule={isPersonApplicant ? 'ApplicantFreeSchoolMealsEligibility' : 'StudentFreeSchoolMealsEligibility'}
                render={(readonly) => <FreeSchoolMealsFields
                    readonly={readonly}
                    onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                />}
            />
            <PermissionCheck
                permissionModule={isPersonApplicant ? 'ApplicantWelfare' : 'StudentWelfare'}
                render={(readonly) => <WelfareOptions
                    readonly={readonly}
                    onConfirmSuccess={async () => dispatch(retrieveProfile({studentId}))}
                />}
            />
        </Container>
    );
};

export default DietaryWelfareEthnicityTab;
